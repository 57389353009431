@value gray300, black200 from "../../styles/colors.module.css";

.root {
  border-top: 1px solid black200;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;
  color: gray300;
}

.errorText {
  margin-top: -8px;
  margin-bottom: 8px;
}
