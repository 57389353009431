@value white300, black100 from "../../../../styles/colors.module.css";

.transOptionsLabels {
  display: flex;
}

.transOptionsLabels > label {
  margin-right: 16px;
}

.transOptionsDropdowns {
  display: grid;
  grid-template-columns: calc(50% - 16px) 16px 50%;
}

.tabGeneralContainer {
  padding-right: 70px;
}

.defaultPadding {
  margin-left: 48px;
}

.valueRow {
  display: flex;
  justify-content: space-between;
}
