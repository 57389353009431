@value white300, gray30040Percent, blueMedium  from "../../styles/colors.module.css";

.root {
  composes: border-radius from "../index.module.css";
  color: white300;

  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  padding: 4px;
  padding-left: 8px;
}

.iconContainer {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.contentContainer {
  padding: 3px 4px 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
}

.buttonContainer *:last-child {
  margin-left: 8px;
}

:global(.ltn.icon).iconInfo > svg {
  fill: gray30040Percent;
}
