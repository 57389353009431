@value black300,gray300 from "../../../styles/colors.module.css";

.sectionTitle,
.assetLoader {
  margin-top: 16px;
  grid-column: 1/-1;
}

.assetLoader {
  display: flex;
  height: 24px;
  justify-content: center;
}

.avatar {
  grid-area: avatar;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 16px;
  width: 100%;
}

.avatarName {
  color: gray300;
  font-weight: 600;
  margin: 8px;
}

.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  overflow: hidden;
}

.metaInfo {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.metaTitle {
  color: gray300;
  font-size: 10px;
  font-weight: 600;
  align-self: flex-end;
}

.metaData {
  color: gray300;
  align-self: flex-end;
  min-width: 70px;
  text-align: right;
}

.buttons {
  grid-area: actions;
  display: flex;
  flex-direction: row;
  height: 32px;
  align-items: center;
  padding: 0 16px;
  border-left: 1px solid black300;
  justify-content: space-between;
}

.input {
  display: flex;
  flex-direction: column;
}

.type {
  color: gray300;
  font-size: 10px;
  font-weight: 600;
}

.name {
  font-size: 12px;
  font-weight: 600;
}
