@value white300, black300, blackPure from "../../../../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.preview,
.previewOff {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 434px;
  height: 244px;
  background-color: blackPure;
  font-size: 14px;
}

.previewOff {
  background-color: black300;
}

.previewSource {
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  gap: 2px;
  margin-bottom: 8px;
}

.infoIcon {
  flex-shrink: 0;
}

.infoText {
  margin-top: 1px;
  font-size: 10px;
  color: white300;
}

.statusMessage {
  font-size: 12px;
}

.hidden {
  display: none;
}
