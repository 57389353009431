@value gray300 from "../../styles/colors.module.css";

.icon {
  fill: gray300;
}

.icon:hover,
.icon:focus {
  fill: #0857c3;
}
