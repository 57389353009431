@value white300 from "../../../styles/colors.module.css";
.root {
  display: flex;
  align-items: center;
  height: 18px;
}

.enabled {
  margin-left: -2px;
}

.label label,
.type label {
  color: white300 !important;
}

.type {
  margin-right: 4px;
}

.type label {
  font-weight: 600;
}

.label.boldLabel label {
  font-weight: 600;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 2px;
}
