@value black300 from "../../../styles/colors.module.css";

.root {
  composes: root from "../controls.module.css";
  background-color: black300;
  grid-template-columns: 120px auto 88px 32px;
  grid-template-areas: "time livepause switch volume";
  grid-column-gap: 4px;
}

.dark {
  composes: dark from "../controls.module.css";
}

.bright {
  composes: bright from "../controls.module.css";
}

.autorepeat {
  grid-area: autorepeat;
}

.autoplay {
  grid-area: autoplay;
}

.time {
  composes: time from "../controls.module.css";
  grid-area: time;
}

.playpause {
  grid-area: playpause;
}

.livepause {
  composes: center from "../controls.module.css";
  grid-area: livepause;
}

.previewSwitch {
  display: flex;
  grid-area: switch;
  justify-content: flex-end;
}

.volume {
  composes: volume from "../controls.module.css";
  composes: right from "../controls.module.css";
  grid-area: volume;
}
