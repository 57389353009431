.tabGeneralContainer {
  padding-right: 22px;
}

.resourceLocationsTitle {
  line-height: 10px;
}

.pushTargetsBtnContainer {
  text-align: center;
  border-radius: 2px;
  background-color: #171a1c;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.expansionTitleMain {
  margin-left: 8px;
}
