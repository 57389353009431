.buttonsContainer {
  position: relative;
  display: flex;
  padding: 0 80px;
  align-items: center;
  justify-content: space-between;
}

.prevButton {
  position: absolute;
  left: 40px;
}

.dialogButton {
  width: 100px;
}
