@value black100, black200, black1000Percent from "../../../styles/colors.module.css";

.contentInnerContainer {
  padding: 32px 48px 0 32px;
}

.shadowSeparator {
  height: 4px;
  opacity: 0.75;
  background-image: linear-gradient(to top, black100, black1000Percent);
}

.statusMessage {
  position: absolute;
  top: 0;
  left: 80px;
  box-sizing: border-box;
  width: 440px;
}

.errorCode {
  display: contents;
}
