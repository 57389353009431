@value black300 from "../../styles/colors.module.css";

.root {
  composes: root from "./index.module.css";
  max-height: fit-content;
}

.title {
  composes: title from "./index.module.css";
  background: black300;
  height: 65px;
}

.rootExpanded {
  composes: rootExpanded from "./index.module.css";
}
