@value blackPure, whitePure, gray200, white400, black200, black300, blue300 from "../../../styles/colors.module.css";

.root {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.volumeBar {
  display: none;
  position: absolute;
  bottom: 30px;
  width: 24px;
  height: 100px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.volumeSliderContainer {
  background: black200;
  padding: 8px 10px;
  height: 100px;
  border-radius: 2px;
}

.dark .volumeSliderContainer {
  background: black200;
}

.bright .volumeSliderContainer {
  background: white400;
}

.volumeSlider {
  position: relative;
  background-color: blackPure;
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.bright .volumeSlider {
  background-color: gray200;
}

.volumeSliderSet {
  position: absolute;
  background: blue300;
  border-radius: 2px;
  width: 100%;
}

.volumeSliderKnob {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  left: -5px;
  background-color: whitePure;
}

.volumeButton:not(.disabled):hover .volumeBar {
  display: block;
}

.volumeButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
}

.volumeButton:not(.disabled):hover {
  height: 130px;
}
