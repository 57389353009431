.root {
  display: flex;
  align-items: center;
}

.label {
  line-height: 17px;
  margin-right: 4px;
}

.value {
  flex-grow: 1;
  min-width: 0;
  margin-top: 3px;
  margin-bottom: 2px;
}
