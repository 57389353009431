@value blue100, black400, black500, gray300, white300 from "../../styles/colors.module.css";

.targetCustomType,
.quality,
.typeBadge,
.typeBadgePlain {
  display: flex;
  align-items: center;
  margin-top: -3px;
  margin-bottom: -4px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.destinationQuality {
  margin-bottom: 0px;
}

.typeBadge {
  composes: border-radius from "../../comp/index.module.css";
  background-color: black400;
  padding-right: 8px;
  color: gray300;
  user-select: none;
}

.typeBadge:hover,
.typeBadge:active,
.typeBadge:focus {
  background-color: black500;
  color: white300;
}

.typeBadge:focus {
  border-color: blue100;
}

.qualityLabel {
  display: block;
  margin-left: 8px;
}

.qualityMetadata {
  flex-basis: 100%;
}

.qualityMetadataLabel {
  color: white300;
  margin-bottom: 8px;
  margin-top: 8px;
}