.root {
  composes: root from "../index.module.css";
}

.title {
  composes: title from "../index.module.css";
}

.message {
  composes: message from "../index.module.css";
}

.icon {
  composes: icon from "../index.module.css";
}

.wrapper {
  composes: wrapper from "../index.module.css";
}

.signal {
  padding: 0;
}

.signal .icon,
.signal .title,
.signal .message {
  width: auto;
  max-width: none;
}

.signal .wrapper {
  width: 100%;
}
