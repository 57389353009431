@value blackPure, whitePure, black200, black100, blue300, gray300 from "../../../styles/colors.module.css";

.root {
  composes: root from "../controls.module.css";

  grid-template-columns: 24px auto 24px;
  grid-template-areas: "playpause timeline volume";
  grid-column-gap: 4px;
}

.dark {
  composes: dark from "../controls.module.css";
}

.bright {
  composes: bright from "../controls.module.css";
}

.playpause {
  grid-area: playpause;
}

.timeline {
  grid-area: timeline;
}

.volume {
  composes: right from "../controls.module.css";
  composes: volume from "../controls.module.css";
  grid-area: volume;
}
