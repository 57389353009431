@value gray300 from "../../styles/colors.module.css";

.root {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  composes: item;
}

.links {
  composes: item;
}

.version {
  composes: item;
  color: gray300;
}
