@value black20030percent, white300 from "../../../../styles/colors.module.css";

.root {
  background-color: black20030percent;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 16px 56px;
}

.report {
  font-weight: 600;
  color: white300;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  color: white300;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
