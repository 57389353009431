@value blackPure, whitePure, white300, whitePure20percent, blackPure80percent, black200, black100, blue300, blue10050Percent, blue400, blue200, gray300, gray200 from "../../../styles/colors.module.css";
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black200;
  margin-top: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.trimmerWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.seekbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  margin: 0 16px;
  background-color: black100;
  height: 6px;
  border-radius: 2px;
}

.hover,
.fill,
.buffer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.fill {
  background-color: blue300;
  z-index: 1;
  border-radius: 2px;
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.buffer {
  background-color: whitePure20percent;
  z-index: 0;
  border-radius: 2px;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.hover {
  background-color: whitePure20percent;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
}

.seekbar:not(.disabled):hover .hover {
  visibility: visible;
  opacity: 1;
}

.timecode {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  color: gray300;
  user-select: none;
}

.timecode.disabled {
  opacity: 0.4;
}

.handle {
  box-sizing: border-box;

  width: 14px;
  height: 14px;

  border-radius: 50%;
  background-color: blue300;

  position: absolute;
  top: 50%;

  visibility: hidden;
  opacity: 0;

  transform: translate3d(-50%, -50%, 0);
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.handle:hover,
.handle:focus {
  background-color: blue400;
  transition: none;
}

.handle:active {
  background-color: blue200;
}

.tooltip {
  composes: border-radius from "../../../comp/index.module.css";
  letter-spacing: 1px;
  color: white300;
  position: absolute;
  top: -40px;
  padding: 4px;
  text-align: center;
  background-color: blackPure80percent;
  visibility: hidden;
  opacity: 0;
}

.seekbar:not(.disabled) .tooltip,
.seekbar:not(.disabled):hover .handle {
  visibility: visible;
  opacity: 1;
}

.trimmer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px;

  position: absolute;
  right: 0;
  left: 0;
  bottom: -7px;
  top: -7px;
  z-index: 2;
}

.trimmerBar {
  background-color: blue10050Percent;
  flex-grow: 1;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.controlTrimStart {
  position: absolute;
  margin-left: -13px;
  margin-top: 2px;
  z-index: 3;
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.controlTrimStart:hover {
  cursor: col-resize;
  transition: none;
}

.controlTrimEnd {
  position: absolute;
  margin-left: -13px;
  margin-top: 2px;
  z-index: 3;
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.controlTrimEnd:hover {
  cursor: col-resize;
  transition: none;
}

.controlsWrapper {
  height: 48px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.inputTrimStart {
  margin-right: 8px;
}

.inputTrimEnd {
  flex-grow: 1;
}

.buttons,
.trim {
  position: relative;
  display: flex;
  align-items: center;
}

.transferButton {
  margin-inline: 8px;
}

.buttons {
  justify-content: space-evenly;
  flex-shrink: 0;
}

.trim {
  flex-grow: 1;
}
