.wrapper {
  display: flex;
}

.avatar {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  overflow: hidden;
  flex-grow: 1;
}
