@value white300 from "../../../styles/colors.module.css";

.titleHolder {
  display: flex;
  color: white300;
  margin-top: 56px;
  font-size: 20px;
  height: 26px;
  line-height: 26px;
  align-items: center;
}

.icon {
  margin-right: 4px;
}

.label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
