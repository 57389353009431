@value black400, whitePure, white300 from "../../styles/colors.module.css";
.graph {
  margin-top: 8px;
}

.graph:first-child {
  margin-top: 0;
}

.graphs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bandwidthGraph {
  width: 300px;
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;
  background: black400;
}

.highlighted {
  color: whitePure;
  font-weight: 600;
}

.videoFramerate {
  padding-top: 6px;
}

.pkgInfo {
  padding-top: 6px;
  display: flex;
  align-items: center;
}

.label {
  padding-left: 8px;
}

.pkgInfo .label {
  color: white300;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.videoFramerate:last-child {
  margin-bottom: 16px;
}

.frameRateGraph {
  background-color: black400;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 16px;
}

.metadataRow {
  padding-top: 8px;
}

.value {
  color: whitePure;
  font-weight: 600;
  padding-left: 8px;
}
