@value blue300 from "../../../styles/colors.module.css";

.sliderContainer {
  composes: sliderContainer from "../index.module.css";
  flex-direction: column;
}

.hitbox {
  composes: hitbox from "../index.module.css";
}

.handle {
  composes: handle from "../index.module.css";
  transition: left 0.1s ease;
  outline: none;
}

.fill {
  composes: fill from "../index.module.css";
}

.lengthFilterInputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
}
