@value black200, black300, black400, black500, gray300, white300, blue100 from "../../styles/colors.module.css";

.root {
  margin: 16px auto;
  max-width: 728px;

  display: flex;
  flex-direction: column;
}

.streams {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 2px;
  grid-column-gap: 2px;
}

.header {
  padding: 19px 0;
  display: flex;
  align-items: center;
}

.title {
  color: white300;
  font-weight: 600;

  margin-right: 16px;
}

.outputCount {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.outputCountLabel {
  color: gray300;

  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.routingCanvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  pointer-events: none;
  display: none;
}
