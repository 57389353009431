@value blackPure from "../../styles/colors.module.css";
.root {
  min-width: 256px;
  max-width: 400px;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1000000;
}

.message {
  white-space: pre-wrap;
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(0, 16px);
  }
}

.fadingOut {
  animation: hide 0.2s ease-out;
}

.reqId {
  margin-top: 2px;
  color: blackPure;
}

.id {
  margin-top: 2px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: max-content;
}

.id div {
  color: blackPure;
  box-shadow: none;
  font-weight: 600;
  justify-content: flex-start;
  width: max-content;
}
