@value blackPure, blue100, black300, black400, white300, gray300, gray10050Percent from "../../styles/colors.module.css";
.root {
  padding: 8px 16px;
  margin-bottom: 4px;

  background-color: black300;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  position: relative;
}

.label {
  font-weight: 600;
  line-height: 32px;
  color: white300;
  user-select: none;
}

.stream {
  display: flex;
  align-items: center;
  flex-direction: row;
  max-height: 32px;
  margin: 8px 0;
  box-sizing: border-box;
}

.signal {
  composes: border-radius from "../../comp/index.module.css";
  background-color: black400;
  padding-right: 8px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  height: 32px;
  width: 236px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
}

.signal:hover,
.signal:active,
.signal:focus {
  background-color: gray10050Percent;
}

.signal:focus {
  border-color: blue100;
}

.signal:focus .title {
  color: white300;
}

.img {
  height: 32px;
  width: 57px;
  margin-right: 16px;
  flex-shrink: 0;
  user-select: none;
}

.title {
  font-weight: 600;
  line-height: 18px;
  color: gray300;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.signal:hover .title,
.signal:active .title {
  color: white300;
}
