@value greenMedium, blackLight, blackDark, black200, gray300, white300 from "../../styles/colors.module.css";

.root {
  composes: border-radius from "../index.module.css";
  background-color: black200;
  padding: 8px;
  width: 100%;
  max-width: 420px;
  display: flex;
  line-height: 17px;
  color: gray300;
  box-sizing: border-box;
}

.head {
  display: flex;
  align-items: center;
}

.iconContainer {
  margin-right: 4px;
}

.errorIconContainer {
  composes: iconContainer;
  padding: 4px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 4px;
  margin-left: 4px;
}

.header {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
}

.headline {
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: white300;
  flex-grow: 0;
  flex-shrink: 0;
}

.messageContainer {
  margin-top: 16px;
}

.buttonContainer {
  margin-left: -32px;
  margin-top: 16px;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}
