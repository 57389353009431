@value black200, black300, black500, gray200, gray300, gray500 from "../../styles/colors.module.css";

.pill {
  composes: pill from "./index.module.css";
  background-color: gray300;
  color: black200;
}

.hasAction {
  composes: hasAction from "./index.module.css";
}

.hasAction:hover {
  background-color: gray500;
}

.delete {
  composes: delete from "./index.module.css";
  background-color: black300;
  border-color: gray300;
}

.hasAction:hover .delete {
  border-color: gray500;
}

.delete path {
  fill: gray500;
}

.delete:hover {
  background-color: black200;
}

.icon {
  composes: icon from "./index.module.css";
}
