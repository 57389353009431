@value white300 from "../../../../styles/colors.module.css";

.root {
}

.topbar {
  padding: 12px 16px 12px 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.topbarIcons {
  flex-shrink: 0;
}

.topbarIcons > * {
  margin-left: 8px !important;
}

.title,
.emptyTitle {
  font-size: 12px;
  font-weight: 600;
  color: white300;
  flex-grow: 1;
  margin-left: 8px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.status {
  margin-bottom: 8px;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100%-20px);
}

.effect {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
