@value gray300, black200, black300, white300, whitePure, red200 from "../../styles/colors.module.css";

.root {
  color: gray300;
  min-height: 48px;
  max-height: 48px;
  box-sizing: border-box;
  outline: none;
  background-clip: padding-box;
  transition: background-color 0.2s ease, border 0.1s ease, max-height 0.5s ease-out;
  margin-bottom: 4px;
}

.root:hover,
.root:focus,
.rootExpanded {
  background-color: black300;
  color: white300;
  flex-shrink: 0;
}

.root[disabled] {
  opacity: 0.4;
  pointer-events: none;
}

.icon {
  width: 18px;
  height: 24px;
  top: 4px;
  position: relative;
  overflow: hidden;
}

.icon > * {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.25s ease-out;
  will-change: transform;
}

.icon > *:first-child {
  top: -4px;
}

.icon > *:last-child {
  top: 4px;
}

.root .title:active {
  color: whitePure;
}

.root:hover .icon path,
.root:focus .icon path {
  fill: white300;
}

.rootExpanded .title:active {
  color: gray300;
}

.root .icon path,
.rootExpanded .title:active .icon svg path {
  fill: gray300;
}

.rootExpanded .icon path,
.root:hover .icon path {
  fill: white300;
}

.rootExpanded .icon > *:first-child {
  transform: translate(0, 9px);
}

.rootExpanded .icon > *:last-child {
  transform: translate(0, -9px);
}

.rootExpanded {
  max-height: 5000px;
}

.title {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: stretch;
  user-select: none;
  padding: 0 16px;
}

.label {
  font-weight: 600;
  flex: 1 1 0;
  min-width: 0;
}

.labelWithIcon {
  composes: label;
  display: flex;
  align-items: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 33px;
  box-sizing: border-box;
}

.divider {
  border-top: 1px solid black200;
}

.content {
  padding-left: 16px;
  padding-bottom: 8px;
}

.content > * {
  padding-right: 16px;
}

.erroneous {
  border: 1px solid red200;
}

.statusIcon,
.actions {
  margin-right: 16px;
}
