@value white300, gray300, black300 from "../../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: column;
}

.topbarContainer {
  width: 100%;
}

.topbar {
  display: flex;
  flex-shrink: 0;
  align-items: center;

  padding: 12px 16px;
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  justify-content: space-between;
}

.topbarIcons {
  display: flex;
  align-items: center;
}

.topbarIcons > * {
  margin-left: 8px !important;
}

.divider {
  width: 1px;
  height: 32px;
  background-color: black300;
}

.title {
  font-size: 12px;
  font-weight: 600;
  color: white300;
}

.device {
  display: flex;
  flex-shrink: 0;
  align-items: center;

  padding: 12px 16px;
  box-sizing: border-box;
  height: 56px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.signals {
  display: grid;
  grid-template-rows: repeat(auto-fill, 182px);
  grid-row-gap: 16px;
}

.map {
  height: calc(100vh - 64px);
}

.mapview {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.locationMap {
  composes: map;
  height: 178px;
  margin-left: -16px;
  margin-right: -16px;
  width: 100%;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sectionTitle {
  margin-top: 16px;
  grid-column: 1/-1;
}

.player {
  margin-bottom: 4px;
}

.grid {
  padding: 0 16px;
}

.user {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.user > div {
  margin-right: 6px;
}

.user span {
  font-size: 12px;
  color: gray300;
}

.placeholder {
  align-self: flex-start;
  padding: 0 16px;
}

.input {
  width: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  margin-inline: 16px;
}

.name {
  color: gray300;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.type {
  font-size: 10px;
}
