@value gray300, black200 from "../../../../../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  gap: 8px;
}

.card {
  composes: border-radius from "../../../../../../comp/index.module.css";
  box-sizing: border-box;
  padding: 4px;
  background-color: black200;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  gap: 8px;
}

.avatar {
  display: flex;
  align-items: center;
}

.email {
  color: gray300;
  flex: 1 0 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.removeButton {
  flex-grow: 0;
  flex-shrink: 0;
}
