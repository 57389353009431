.productionLabel {
  display: flex;
  align-items: center;
}

.labelText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.labelText.withLifespan {
  width: 106px;
  padding-right: 16px;
  box-sizing: border-box;
}

.lifespan {
  margin-left: 0;
}

.alertIcon {
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
