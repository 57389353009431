.root {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 100%;
  box-sizing: border-box;
}

.expansionPanel {
  flex-shrink: 0;
}
