@value black400, white500, white30050percent from "../../styles/colors.module.css";
.root {
  position: relative;

  display: flex;
  align-items: center;

  composes: border-radius from "../index.module.css";
  padding: 0 4px 4px 4px;
}

.rootNoPadding {
  position: relative;

  display: flex;
  align-items: center;

  composes: border-radius from "../index.module.css";
}

.actions {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.actions > * {
  margin-right: 8px;
}

.open {
  composes: border-radius box-shadow from "../index.module.css";
  position: absolute;

  display: flex;
  align-items: center;

  background-color: white500;
  right: 0;
  padding: 4px;
}
