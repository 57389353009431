@value whitePure, blackPure, black100, gray300, white300 from "../../../../../styles/colors.module.css";

.overlayTabContainer {
  /*height: 100%;*/
  margin-right: 67px;
}

.overlayContainer {
  width: 440px;
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.overlayInnerContainer {
  height: 248px;
  width: 100%;
  z-index: 0;
  position: absolute;
}

.transparent {
  background-image: url("./bg-transparent.png");
  background-size: cover;
}

.bright {
  background: whitePure;
}

.dark {
  background: blackPure;
}

.noOverlayContainer {
  position: absolute;
  text-align: center;
}

.fileInput {
  display: none;
}

.overlayTopOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlayOptionsBg {
  display: flex;
  align-items: center;
  gap: 4px;
}

.overlayOptionsBg p {
  margin-right: 11px;
}

.overlayOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white300;
  font-size: 10px;
}

.qualityOverlayInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlayInfoMessageContainer {
  display: flex;
  align-items: center;
}

.overlayInfoMessage {
  color: white300;
  font-size: 10px;
  margin-left: 2px;
}

.overlayPositionLabels label:last-child {
  margin-left: 32px;
}

.overlayHoverOptions {
  display: none;
  z-index: 1;
  width: 440px;
  height: 248px;
  position: absolute;
}

.overlayHoverOptions > button {
  margin-right: 8px;
}

.overlayContainer:hover .overlayHoverOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayPosition {
  box-sizing: border-box;

  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid black100;
}

.overlayPositionTextFields {
  display: flex;
  align-items: center;
}

.overlayPositionIcon {
  align-self: flex-end;
  margin-bottom: 2px;
}

.overlayPositionField {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 8px;
}

.overlayPositionField:first-child {
  margin-left: 4px;
}

.overlayPositionButtons {
  display: flex;
  align-items: center;

  margin-left: 80px;
  margin-top: 20px;

  width: 100%;
  gap: 4px;
  justify-content: flex-end;
}

.warning {
  margin-left: 48px;
}

.ml48 {
  margin-left: 48px;
}

.radioGroup {
  border-bottom: 1px solid black100;
}