.inviteResults {
  width: 100%;
  margin-bottom: 8px;
}

.fadeOut {
  animation: fadeOut 3s linear 3s forwards;
}

@keyframes fadeOut {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  90% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  100% {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}
