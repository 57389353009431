@value gray300 from "../../../../styles/colors.module.css";

.qualityButton {
  width: 82px;
  margin-right: 8px;
}

.qualityRow {
  user-select: none;
  display: flex;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleLabel {
  flex-grow: 0;
}

.titleInfo {
  font-size: 10px;
  font-weight: 600;
  flex-grow: 1;
  color: gray300;
  margin-left: 3px;
  margin-bottom: 4px;
}

.titleInfo:before {
  content: "- ";
  font-weight: normal;
}

.rowInfo {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}