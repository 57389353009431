@value black300, white300, blueMedium from "../../../styles/colors.module.css";

.navigation {
  background: black300;
  width: 280px;
  height: 100%;
  padding: 16px;
  color: white300;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: column;
}

.spacer {
  flex-grow: 1;
}

.pills div {
  margin-right: 4px;
}

.error a {
  text-decoration: none;
  color: blueMedium;
}
