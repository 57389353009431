.root {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 12px;
}

.passiveSignal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.activeSignal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.compareToBtn {
  margin-right: 8px;
}