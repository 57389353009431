.root {
  position: relative;
  height: 100%;
  width: 100%;
}

.player {
  position: relative;
  height: 100%;
  width: 100%;
}

.fullscreenButton {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.stream:hover .fullscreenButton {
  display: block;
}
