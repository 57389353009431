.root {
  composes: root from "../index.module.css";
}

.title {
  composes: title from "../index.module.css";
  margin-bottom: 8px;
  max-width: 268px;
}

.message {
  composes: message from "../index.module.css";
  max-width: 268px;
}

.search {
  align-self: flex-start;
}

.navigation {
  width: 188px;
  margin-top: 43px;
  margin-bottom: 32px;
}

.action {
  composes: action from "../index.module.css";
}
