.serviceRegionContainer {
  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: 4px;
}

.credentialsContainer {
  display: flex;
  justify-content: space-between;
}

.oneCredentialContainer {
  width: 212px;
}
