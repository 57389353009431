@value black100, black200, black300, black400, black500, gray300, gray100, green400, white300 from "../../styles/colors.module.css";

.root {
  display: flex;
  position: relative;
  padding: 12px 16px;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.icon {
  margin: 0 auto;
  width: 112px;
}

.message {
  line-height: 16px;
  font-size: 12px;
  color: gray300;
  max-width: 188px;
  text-align: center;
}

.title {
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: gray300;
  margin-bottom: 4px;
}

.action {
  position: relative;
}

.action button {
  margin-top: 32px;
  height: 48px !important;
}

.action button svg {
  width: 37px;
  height: 37px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
