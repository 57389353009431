.roles {
  display: inline;
  width: 100%;
  flex-grow: 0;
  white-space: nowrap;
}

.badge {
  display: inline-block;
  max-width: 100%;
}

.badgeWrapper {
  display: inline;
  white-space: nowrap;
}

.content:not(:first-child) .badgeWrapper,
.overflowCount {
  margin-left: 8px;
}

.overflowBadge {
  visibility: hidden;
}
