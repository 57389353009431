.content {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: calc(100vh - 64px);
  min-width: 800px;
  background-color: #171a1c;
  position: relative;
}

.main {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 64px);
  flex-grow: 0;
  flex-shrink: 1;
}

.scrollable {
  scrollbar-gutter: stable both-edges;
  overflow-y: auto;
}
