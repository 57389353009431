@value black300 from "../../styles/colors.module.css";

.divider {
  width: 1px;
  display: inline-block;
  position: relative;
  top: 4px;
  height: 32px;
  background-color: black300;
}
