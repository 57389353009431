@value gray300, white300 from "../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  min-height: 32px;
  position: relative;

  box-sizing: border-box;
  padding: 6px 0;
}

.key {
  color: gray300;
  font-weight: 600;
  display: inline-flex;
  max-width: 96px;
  min-width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
  line-height: inherit;
  align-self: flex-start;
  flex-grow: 0;
  align-items: center;
  padding-top: 1px;
}

.key path {
  fill: gray300;
}

.value {
  color: white300;
  text-overflow: ellipsis;
  line-height: normal;
  min-width: 0;
  flex-grow: 1;
}
