@value blackPure, whitePure, white300, whitePure20percent, blackPure80percent, black200, black100, blue300, blue10050Percent, blue400, blue200, gray300 from "../../../styles/colors.module.css";
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timelineWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.seekbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  margin: 0 8px;
  background-color: black100;
  height: 6px;
  border-radius: 2px;
}

.hover,
.fill,
.buffer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.fill {
  background-color: blue300;
  z-index: 1;
  border-radius: 2px;
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.buffer {
  background-color: whitePure20percent;
  z-index: 0;
  border-radius: 2px;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.hover {
  background-color: whitePure20percent;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
}

.seekbar:hover .hover {
  visibility: visible;
  opacity: 1;
}

.timecode {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  color: gray300;
  user-select: none;
}

.handle {
  box-sizing: border-box;

  width: 14px;
  height: 14px;

  border-radius: 50%;
  background-color: blue300;

  position: absolute;
  top: 50%;

  visibility: hidden;
  opacity: 0;

  transform: translate3d(-50%, -50%, 0);
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.handle:hover,
.handle:focus {
  background-color: blue400;
  transition: none;
}

.handle:active {
  background-color: blue200;
}

.slider {
}

.tooltip {
  composes: border-radius from "../../../comp/index.module.css";
  letter-spacing: 1px;
  color: white300;
  position: absolute;
  top: -40px;
  padding: 4px;
  text-align: center;
  background-color: blackPure80percent;
  visibility: hidden;
  opacity: 0;
}

.seekbar:hover .tooltip,
.seekbar:hover .handle {
  visibility: visible;
  opacity: 1;
}
