@value gray300 from "../../../../../styles/colors.module.css";

.scroller {
  overflow-y: scroll;
  height: 541px;
  margin-right: -78px;
  margin-left: -80px;
  padding-left: 80px;
  padding-bottom: 16px;
}

.configurationTop {
  width: 440px;
}

.policyItem {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.dropdown.policyItem {
  margin: 8px 0;
}

.policyItemName {
  line-height: 18px;
}

.policyItemPath {
  line-height: 18px;
  color: gray300;
}

.policyItemMethodEffect {
  line-height: 18px;
  display: flex;
  color: gray300;
}

.policyItemMethods {
  flex-grow: 1;
}

.policyItemEffect {
  align-self: flex-end;
}

.selectedPolicies {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
}

.withRemoveButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.removeItem {
  width: 32px;
  margin-right: 8px;
}

.policyCard {
  flex-grow: 0;
  width: 440px;
}

.showUiItems {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.showUiItems > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
