@value black200, black400 from "../../../styles/colors.module.css";

.root {
  background-color: black400;
  padding: 16px;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.list > div {
  max-width: 268px;
}

.list.fallbackList {
  margin-top: 8px;
  margin-bottom: -8px;
}

.divider {
  height: 1px;
  background-color: black200;
}
