.root {
  display: flex;
  font-weight: normal;
}

.leftColumn {
  display: flex;
}

.rightColumn {
  display: flex;
  align-items: center;
  margin: 0 8px;
}

.datetime {
  margin-right: 16px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description {
  width: 208px;
  line-height: normal;
}

.title,
.subtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  font-weight: 600;
}

.subtitle {
  font-size: 10px;
}
