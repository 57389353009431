@value white300, black400 from "../../styles/colors.module.css";
.root {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pill {
  font-weight: 600;
}

.divider {
  width: 1px;
  height: 17px;
  background-color: black400;
  display: block;
  border-radius: 1px;
  margin-inline: 4px;
}

.signalDescription {
  padding-right: 4px;
  color: white300;
  font-weight: 600;
}