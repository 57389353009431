@value black400, white300, gray300 from "../../../styles/colors.module.css";

.channelCardContainer {
  composes: border-radius from "../../../comp/index.module.css";
  display: flex;
  background-color: black400;
  justify-content: space-between;
  align-items: center;
  padding: 16px 21px 16px 14px;
}

.iconCenterContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  display: inline-block;
}

.centerText {
  display: inline-block;
  color: white300;
}

.rightTextContainer {
  width: 24px;
  height: 24px;
  text-align: center;
}

.rightText {
  color: white300;
  line-height: 24px;
}

.channelCardContainer[data-size="small"] {
  padding: 4px 21px 4px 14px;
}

.channelCardContainer[data-size="small"] .centerText {
  color: gray300;
}
