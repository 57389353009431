@value blackPure, whitePure, black200, black100, blue300, gray300 from "../../../styles/colors.module.css";

.root {
  composes: root from "../controls.module.css";

  grid-template-columns: 24px 24px auto 24px auto 52px;
  grid-template-areas: "autorepeat autoplay rewind playpause forward volume";
  grid-column-gap: 4px;
}

.dark {
  composes: dark from "../controls.module.css";
}

.bright {
  composes: bright from "../controls.module.css";
}

.autorepeat {
  grid-area: autorepeat;
}

.autoplay {
  grid-area: autoplay;
}

.rewind {
  composes: right from "../controls.module.css";
  grid-area: rewind;
}

.time {
  composes: time from "../controls.module.css";
  grid-area: time;
}

.playpause {
  composes: center from "../controls.module.css";
  grid-area: playpause;
}

.livepause {
  grid-area: livepause;
}

.volume {
  composes: right from "../controls.module.css";
  composes: volume from "../controls.module.css";
  grid-area: volume;
}
