.root {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: none;
}

.firstSegment {
  font-weight: 600;
}
