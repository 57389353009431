@value black300 from "../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 56px 56px 56px;
  align-items: center;
}

.iconUser {
  background-color: black300;
  border-radius: 2px;
}
