@value blue300 from "../../styles/colors.module.css";

.root {
  box-sizing: border-box;
  height: 4px;

  border-radius: 2px;

  position: relative;
  top: 8px;
}

.sliderContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  align-items: center;
}

.sliderContainer[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

.hitbox {
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.root:focus {
  outline: none;
}

.handle {
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  border-radius: 50%;

  position: absolute;
  top: 50%;

  transform: translate3d(-50%, -50%, 0);
}

.fill {
  position: absolute;
  background-color: blue300;
  height: 4px;
  border-radius: 2px;
}

.min,
.max {
  user-select: none;
  min-width: 18px;
  line-height: 18px;
  text-align: center;
}

.min {
  margin-right: 8px;
  flex-shrink: 0;
}

.max {
  margin-left: 8px;
  flex-shrink: 0;
}

.lhsInputHandle {
  margin-right: 8px;
}

.rhsInputHandle {
  margin-left: 8px;
}
