@value blue300,
black100 from "../../../../styles/colors.module.css";

.pill {
  color: black100 !important;
  font-weight: 600;
}

.sourceIcon {
  margin-right: 0 !important;
}

.sourceItem {
  margin-top: 10px;
}