.root {
  padding-right: 70px;
  margin-left: 48px;
}

.serviceRegionContainer {
  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: 4px;
}

.credentialsContainer {
  display: flex;
  justify-content: space-between;
}

.oneCredentialContainer {
  width: 212px;
}

.buffers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bufferField {
  width: 212px;
  display: flex;
  align-items: center;
}

.message {
  margin-bottom: 16px;
}
