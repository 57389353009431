.qualityConfiguration {
  display: flex;
  flex-direction: column;
}

.failoverObject {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.failoverSource {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
}

.failoverSourceUrl {
  display: block;
  min-width: 0;
}

.failoverSourceAvailability {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spinner {
  width: 1em !important;
  height: 1em !important;
}
