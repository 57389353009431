@value blackPure50percent, whitePure50percent, black200, gray100, green300 from "../../styles/colors.module.css";

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.default {
  width: 24px;
  height: 24px;
}

.small {
  width: 20px;
  height: 20px;
}

.default .background {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  padding: 2px;
}

.small .background {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  padding: 1px;
}

.bright .background {
  width: 10px;
  height: 10px;
  padding: 2px;
}

.background {
  box-sizing: border-box;
}

.dark .background {
  background: blackPure50percent;
}

.bright .background {
  background: whitePure50percent;
}

.default .root {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.small .root {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

.root {
  stroke-width: 2px;
  stroke: blackPure50percent;
  user-select: none;
}

.offline {
  composes: root;
  background-color: black200;
}

.enabled {
  composes: root;
  background-color: gray100;
}

@keyframes starting-anim {
  0% {
    background: black200;
  }
  95% {
    background: green300;
  }
  100% {
    background: black200;
  }
}

.starting {
  composes: root;
  animation: starting-anim 3s infinite;
}

.online {
  composes: root;
  background-color: green300;
}

@keyframes transmitting-anim {
  0% {
    background: green300;
  }

  70% {
    background: green300;
  }
  71.5% {
    background: black200;
  }
  73% {
    background: black200;
  }
  74.5% {
    background: green300;
  }

  80% {
    background: green300;
  }
  81.5% {
    background: black200;
  }
  83% {
    background: black200;
  }
  84.5% {
    background: green300;
  }

  90% {
    background: green300;
  }
  91.5% {
    background: black200;
  }
  93% {
    background: black200;
  }
  94.5% {
    background: green300;
  }

  100% {
    background: green300;
  }
}

.transmitting {
  composes: root;
  animation: transmitting-anim 3s infinite;
}

@keyframes stopping-anim {
  0% {
    background: green300;
  }
  95% {
    background: black200;
  }
  100% {
    background: green300;
  }
}

.stopping {
  composes: root;
  animation: stopping-anim 3s infinite;
}
