@value black100, black300, gray300, white300 from "../../../styles/colors.module.css";
.root {
  user-select: none;
  color: white300;
}

.topbar {
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.title {
  flex-grow: 1;
}

.buttonReset {
  margin-right: 8px;
}

.buttonEdit {
}

.filter {
  margin-left: 16px;
  padding: 12px 0;
  border-bottom: 1px solid black100;
}

.userFilter {
  margin-top: 13px;
}

.userList {
  margin-top: 8px;
}

.lengthFilter {
  margin-top: 13px;
}

.buttonWithAction {
  width: 268px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}

.buttonWithAction:first-child {
  margin-top: 0;
}

.buttonActionContainer {
  margin-left: 8px;
}

.resolutionFilter {
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  color: gray300;
}

.resolutionFilterItem span {
  padding-bottom: 4px;
}

.datePicker {
  display: flex;
  flex-direction: column;
}

.datePicker > div:first-child {
  position: static;
}

.expiration {
  font-size: 10px;
  color: gray300;

  background-color: black300;

  display: flex;
  align-items: center;
  margin-top: 6px;
}

.days {
  font-weight: 600;
}
