@value gray300 from "../../styles/colors.module.css";
.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 150px;
  padding-bottom: 20px;
}

.icon {
  margin-bottom: 32px;
  width: 120px;
}

.text {
  font-size: 14px;
  color: gray300;
  margin-bottom: 32px;
}
