@value gray300 from "../../styles/colors.module.css";

.container {
  width: 268px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonContainer {
  margin-left: 8px;
}
