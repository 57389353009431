.effectItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.wrapper {
  display: flex;
  gap: 8px;
  flex-grow: 1;
}

.dropdown {

}

.textField {
  flex-grow: 1;
}

.tooltip {
  display: flex;
  align-items: center;
  flex-grow: 0;
}
