@value gray100, gray300 from "../../../styles/colors.module.css";

.dialogContainer {
  composes: border-radius box-shadow from "../../index.module.css";

  box-sizing: border-box;

  width: 880px;
  height: 698px;
  border-radius: 8px;
  border: 1px solid gray100;

  display: flex;
  flex-direction: row;
  align-items: center;

  user-select: none;

  will-change: opacity, transform, filter, blur;

  transform: translateZ(0);
  filter: blur(0px);

  animation: show 0.2s;

  color: gray300;
}
