@value gray300 from "../../../../../styles/colors.module.css";

.header {
  user-select: none;
  width: 100%;
  height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: gray300;
}

.headerLeft {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 16px;
}
