@value black300 from "../../../styles/colors.module.css";

.root {
  composes: root from "./index.module.css";
  padding: 0;
  border: none;
}

.inner {
  composes: inner from "./index.module.css";
  background-color: black300;
}

.thumbnail {
  composes: thumbnail from "./index.module.css";
}

.overlay {
  composes: overlay from "./index.module.css";
}

.media {
  composes: media from "./index.module.css";
}

.input {
  composes: input from "./index.module.css";
}

.label {
  composes: label from "./index.module.css";
}

.inputInfo {
  composes: inputInfo from "./index.module.css";
}

.inputInfoItems {
  composes: inputInfoItems from "./index.module.css";
}

.content {
  composes: content from "./index.module.css";
}

.stage {
  composes: stage from "./index.module.css";
}

.inspector {
  composes: inspector from "./index.module.css";
}

.routed {
  composes: routed from "./index.module.css";
}

.selected {
  composes: selected from "./index.module.css";
}

.highlighted {
  composes: highlighted from "./index.module.css";
}
