@value black300, gray300 from "../../../../../styles/colors.module.css";
.root {
  margin-left: 48px;
}

.root,
.expansionRow {
  padding-right: 70px;
}

.dialogRow {
  display: flex;
  flex-direction: row;
}

.rowColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  width: 50%;
  box-sizing: border-box;
  margin-right: 16px;
}

.rowColumn:first-child {
  margin-right: 25px;
}

.fieldRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.tooltip {
  margin-left: 6px;
  flex-grow: 0;
}

.encryptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: stretch;
}

.controls {
  background-color: black300;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 2px;
}

.expansionContent {
  padding: 0 16px 16px 16px;
}

.expansionLabel {
  font-weight: 400 !important;
  color: gray300 !important;
  display: flex;
  flex-direction: column;
}
