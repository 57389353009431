$body-background-color: #171a1c // black-100
$body-overflow-y: hidden

@import "@make.tv/lib-web-uicomponents/build/sass/main"

html,
body
  height: 100%

#root
  width: 100%
  height: 100%

// Ex "center" react component turned into a helper css class
.lvc-center-container
  height: 100%
  padding: 0
  margin: 0
  display: flex
  align-items: center
  justify-content: center

  &.--horizontal
    align-items: flex-start

// Negative margins
.myn-2
  margin-top: -2px !important
  margin-bottom: -2px !important