@value gray300 from "../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 56px 56px 56px;
  align-items: center;
}

.header {
  user-select: none;
  width: 100%;
  height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray300;
}

.headerLabel {
  margin-right: 8px;
}

.stage {
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  justify-content: center;
}
