.root {
  margin: 0 56px 16px;
  max-width: 100%;
  box-sizing: border-box;
}

.hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
  margin-top: 0;
  margin-bottom: 0;
  transition: height 0.25s ease-out, opacity 0.25s ease-out, margin 0.25s ease-out;
}

.visible {
  height: 32px;
  opacity: 1;
  transition: height 0.25s ease-in, opacity 0.25s ease-in, margin 0.25s ease-in;
}
