@value blackPure, red500, red20050Percent, blue200, black200, gray300, whitePure, white300, whitePure80percent from "../../styles/colors.module.css";

.root {
  position: relative;

  transition: transform 0.1s ease, background-color 0.25s ease;
  user-select: none;
  border: 1px solid transparent;

  will-change: transform;
}

.routeAction {
  position: absolute;
  padding: 4px;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.root:not([disabled]):hover .routeAction,
.root:not([disabled])[is-routing-source] .routeAction {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.routeAction {
  position: absolute;
  padding: 4px;
  top: 0;
  left: 0;
  z-index: 10;
}

.routeAction {
  position: absolute;
  padding: 4px;
  top: 0;
  left: 0;
  z-index: 10;
}

.statusPlaceholder {
  background-color: black200;
  color: gray300;
  font-size: 10px;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  width: 181px;
  min-height: 100px;
  height: 100%;
  z-index: 5;
}

.stream {
  position: relative;
  transition: opacity 0.25s ease-in-out;
  width: 181px;
  height: 100px;
}

.image {
  position: relative;
}

.highlighted {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid red500;
  box-shadow: 0 0 0 4px red20050Percent inset;
}

.stream img {
  width: 181px;
  height: 100px;
}

.index {
  background-color: black;

  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  z-index: 3;

  composes: border-radius from "../../comp/index.module.css";
  color: gray300;
  font-size: 10px;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease-in-out;
}

.streamName {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  z-index: 6;
  height: 24px;

  margin-left: 22px;
  padding-left: 6px;
  padding-right: 4px;

  composes: border-radius from "../../comp/index.module.css";
  font-size: 10px;

  color: blackPure;
  background-color: whitePure80percent;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.streamName span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes show-blue {
  from {
    opacity: 0;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    border-radius: 2px;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
  }
}

.selected:after {
  content: "";
  border-radius: 2px;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 1px solid blue200;
  animation: show-blue 0.2s;
  z-index: 1005;
  pointer-events: none;
}

.selected .index,
.root:hover .index {
  background-color: whitePure;
  color: blackPure;
  transition: opacity 0.25s ease-in-out;
}

.selected .streamName,
.root:hover .streamName {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.root[disabled] .stream img {
  filter: grayscale(100%);
}
