@value white300, whitePure20percent, black100, black200, blackPure80percent from "../../styles/colors.module.css";

.root {
  position: relative;
  width: 100%;
  max-width: 2600px;
  margin: 0 auto;
  height: auto;
}

.miniPlayer {
  position: absolute;
  top: 8px;
  right: 8px;
}

.miniPlayerLabel {
  display: none;
  border-radius: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 0 8px;
  line-height: 20px;
  font-size: 10px;
  font-weight: 600;
  color: white300;
  background-color: blackPure80percent;
}

.miniPlayerOverlay {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: whitePure20percent;
}

.root:hover .miniPlayerLabel {
  display: block;
}

.miniPlayer:hover .miniPlayerOverlay {
  display: block;
}

.placeholder {
  background-color: black100;
  padding: 0 16px;
  width: 163px;
  height: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previewWarning {
  max-width: 530px;
  margin-top: 16px;
}

.titleBar {
  display: flex;
  height: 32px;
  gap: 4px;
  align-items: center;
  padding: 0 12px;
  background-color: black200;
}
