@value blackPure from "../../styles/colors.module.css";

@keyframes show-backdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes hide-backdrop {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
  }
}

.backdrop {
  background-color: blackPure;

  user-select: none;
  pointer-events: all;
  will-change: opacity;

  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0.7;

  animation: show-backdrop 0.1s ease;
}

.backdrop[is-closing] {
  animation: hide-backdrop 0.2s ease;
}
