@value gray10050Percent, black300, black400, black500, gray100, gray300, white300, blue100 from "../../styles/colors.module.css";

.root {
  composes: border-radius from "../index.module.css";
  padding: 3px 7px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: black400;
  border: 1px solid transparent;
  outline: none;
  align-items: center;
  transition: border-color 0.075s ease, background-color 0.075s ease;
}

.dark {
  /* keep this empty style so other selectors dont break */
}

.bright {
  color: gray300;
}

.root.dark {
  background-color: black300;
  font-weight: 600;
}

.root[size-v="large"] {
  margin-bottom: 8px;
  height: 56px;
  padding: 15px 8px;
}

.root[size-v="medium"],
.root[size-v="medium-double-space"] {
  margin-bottom: 4px;
  height: 32px;
  padding: 3px 8px;
}

.root[size-v="medium-double-space"] {
  margin-bottom: 8px;
}

.root[size-v="small"] {
  margin-bottom: 8px;
  margin-right: 8px;
  height: 24px;
  padding: 4px 8px;
}

.root[size-h="default"] {
  overflow: hidden;
  white-space: nowrap;

  display: inline-flex;
  min-width: 100px;
}

.root[size-h="min"] {
  overflow: hidden;
  white-space: nowrap;

  max-width: 100px;
}

.root[size-h="max"] {
  overflow: hidden;
  white-space: nowrap;

  width: 100%;
}

.root[size-v="small"] {
  padding: 2px 2px 2px 1px;
  margin-bottom: 0;
  margin-right: 0;
}

.root[size-v="small"] .label {
  margin: 0 4px 0 2px;
}

.root:not(:disabled):not(.disabled):not(.disableUserSelect):hover,
.root:not(:disabled):not(.disabled):not(.disableUserSelect):active {
  background-color: gray10050Percent;
}

.root.dark:not(:disabled):not(.disabled):not(.disableUserSelect):hover,
.root.dark:not(:disabled):not(.disabled):not(.disableUserSelect):active {
  background-color: black500;
  outline: none;
}

.root:not(:disabled):not(.disabled):not(:hover):not(.disableUserSelect):focus {
  background-color: gray10050Percent;
}

.root.dark:not(:disabled):not(.disabled):not(:hover):not(.disableUserSelect):focus {
  background-color: black400;
  outline: none;
}

.root:not(:disabled):not(.disabled):hover .actions,
.root:not(:disabled):not(.disabled):focus .actions,
.root:not(:disabled):not(.disabled):active .actions,
.root.focusSelects:focus .actions {
  display: flex;
}

.root:not(:disabled):not(.disabled):not(.disableUserSelect):active,
.bright.selected,
.dark.selected,
.focusSelects:focus {
  border-color: blue100;
}

.bright.selected {
  background-color: black500;
}

.bright.selected,
.bright.focusSelects:focus {
  color: white300;
}

.highlighted,
.highlighted.dark {
  border-color: gray100;
  background-color: black500;
}

.highlighted,
.root:not(:disabled):not(.disabled):not(.disableUserSelect):hover {
  color: white300;
}

.disabled {
  user-select: none;
  opacity: 0.4;
  cursor: default;
}

.label {
  height: 24px;
  line-height: 24px;
  margin: 0 4px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.actions {
  display: none;
  align-items: center;
}

.showActions {
  display: flex;
}

.actions > * {
  margin-left: 8px !important;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 0;
}

.icons {
  display: flex;
  align-items: center;
}

/* status-icon has a box 24x24, therefore additional margin-right is too much. We should refactor it some day. */
.icons > *:not(:global(.status-icon)) {
  margin-right: 4px;
}
