@value black200, black300 from "../../../styles/colors.module.css";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 268px;
  padding: 4px 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  border-radius: 2px;
  line-height: 16px;
  height: 24px;
}

.light {
  background-color: black300;
}

.dark {
  background-color: black200;
}

.source {
  margin-left: 12px;
  flex: 0.62 0 100px;
}

.target {
  margin-right: 8px;
  flex: 0.38 0 56px;
}

.type,
.divider,
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  width: 16px;
}

.type {
  display: flex;
  justify-content: center;
}

.divider {
  margin: 0 12px;
}
