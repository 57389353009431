.listItem {
  height: 20px;
  margin-top: -4px;
}

.location {
  position: relative;
  margin-top: 4px;
  margin-bottom: 8px;
  composes: border-radius from "../../comp/index.module.css";
  overflow: hidden;
  width: 268px;
}

.ingestedTo {
  padding-right: 16px;
  margin-top: 4px;
  margin-bottom: 12px;
}
