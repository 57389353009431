@value blackPure, blackPure80percent, white300, blue200, red500, red50050Percent from "../../styles/colors.module.css";
@value gray300, gray10030Percent, whitePure80percent, whitePure from "../../styles/colors.module.css";

.root {
  margin: 0 auto;
  width: fit-content;
  position: relative;
}

.videos {
  width: 100%;
  height: auto;
  will-change: width, height;
  transition: width 0.1s ease, height 0.1s ease;
}

.tileCanvas {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  width: 0;
  height: 0;
  transition: width 0.1s ease, height 0.1s ease, transform 0.1s ease, background-color 0.25s ease;
  user-select: none;
  pointer-events: none;
  will-change: transform, width, height;
  text-rendering: optimizeLegibility;
}

.tile {
  box-sizing: border-box;
  /*visibility: hidden;*/
  position: absolute;
  z-index: 1002;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  width: 0;
  height: 0;

  padding: 3px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  transition: width 0.1s ease, height 0.1s ease, transform 0.1s ease, background-color 0.25s ease;
  user-select: none;

  will-change: transform, width, height;
}

.tileTopBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;
}

.tile[disabled] {
  pointer-events: none;
}

.tile:not([disabled]):hover {
  background-color: rgba(250, 250, 250, 0.2);
}

.tile:not([disabled]):hover .routingButton,
.tile:not([disabled]):hover .tileFullscreen,
.tile[is-routing-source] .routingButton {
  opacity: 1;
}

.tile[is-selected]:not([reduced-ui]):not([disabled]) .tileNameContainer,
.tile[is-related]:not([reduced-ui]):not([disabled]) .tileNameContainer,
.tile:not([reduced-ui]):not([disabled]):hover .tileNameContainer,
.tile .showLabels .tileNameContainer {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding-left: 6px;
  margin-left: -2px;
}

.tile[is-routed] {
  border: 2px solid red500;
}

.tile[is-routed][is-selected] {
  border-radius: 4px;
}

@keyframes show-blue {
  from {
    opacity: 0;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    border-radius: 4px;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }
}

.tile[is-selected]:after {
  content: "";
  border-radius: 4px;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 2px solid blue200;
  animation: show-blue 0.2s;
  z-index: 1003;
  pointer-events: none;
}

.tile[disabled] {
  filter: saturate(0.2) brightness(0.25);
}

.tile:not([is-routed]).focusRoutings .tileLabel {
  filter: saturate(0.2) brightness(0.25);
}

.focusRoutings:hover .tileLabel,
.tile:not([is-routed]).focusRoutings:hover .tileLabel {
  filter: none;
}

.tileLabel {
  position: relative;
  align-items: center;
  box-sizing: border-box;
  line-height: 24px;
  font-size: 10px;
  color: white300;
  margin-top: auto;
  display: flex;
}

.tileLabel .tileNameContainer {
  display: flex;
  width: 0;
  justify-content: flex-start;
  gap: 4px;
  z-index: 3;
}

.tile[is-selected] .tileNameContainer,
.tile[is-related] .tileNameContainer {
  color: blackPure;
  background-color: whitePure80percent;
}

.sourceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attentionIcon {
  margin-right: 4px;
}

.tileLabelIndex {
  composes: border-radius from "../../comp/index.module.css";
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: white300;
  font-size: 10px;
  border-radius: 2px;
  background-color: blackPure;
  text-align: center;
  flex-shrink: 0;
  z-index: 2;
}

.tileNameContainer {
  composes: border-radius from "../../comp/index.module.css";
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 0;
  min-width: 0;
  max-width: 0;
  gap: 4px;
  background-color: blackPure80percent;
  transition: width 0.1s ease, color 0.2s ease, background-color 0.2s ease;
}

.tileLabelName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.tile[is-selected] .tileLabelIndex,
.tile[is-related] .tileLabelIndex {
  color: blackPure;
  background-color: whitePure;
}

.tileRoutingInfo {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.tileRoutingIndexes {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  letter-spacing: -0.3px;
  color: blackPure80percent;
  font-size: 10px;
  padding: 0 4px;
  border-radius: 2px;
  background-color: white300;
  text-align: center;
}

.tile[is-selected] .tileRoutingIndexes {
  color: whitePure80percent;
  background-color: blackPure;
}

.routingButton {
  opacity: 0;
}

.tileFullscreen {
  opacity: 0;
  transition: opacity 0.1s ease;
}

.tile[is-routing-source] .tileRouting,
.tile[is-routing-target] .tileRouting {
  color: whitePure;
  background-color: blackPure;
}

.tile[is-routing-source] .tileRoutingOuterCircle,
.tile[is-routing-source] .tileRoutingInnerCircle,
.tile[is-routing-target] .tileRoutingOuterCircle,
.tile[is-routing-target] .tileRoutingInnerCircle {
  background-color: rgba(0, 0, 0, 0);
}

.tile[reduced-ui][is-routing-target] .tileRouting {
  visibility: hidden;
}

.tile[no-fullscreen-btn] .tileFullscreen {
  visibility: hidden;
}

.outputFailover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
