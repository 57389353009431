.productionLabel {
  display: flex;
  align-items: center;
}

.productionLabel span {
  min-width: 108px;
}

.lifespan {
  margin-left: auto;
}

.failoverObject {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.failoverSource {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
}

.failoverSourceUrl {
  display: block;
  min-width: 0;
}

.failoverSourceAvailability {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
