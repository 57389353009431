@value white300 from "../../../styles/colors.module.css";

.user {
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.username {
  color: white300;
  margin-left: 8px;
  user-select: none;
}
