@value white300 from "../../styles/colors.module.css";

.root {
}

.topbar {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.topbarIcons {
  display: flex;
}

.topbarIcons > *,
.actions > * {
  margin-left: 8px !important;
}

.title {
  font-size: 12px;
  font-weight: 600;
  color: white300;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status {
  margin-bottom: 8px;
}
