@value gray300, blackPure, whitePure from "../../styles/colors.module.css";

.root {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inputSignalsHeader {
  padding: 0 56px;
  user-select: none;
  height: 32px;
  margin: 13px 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: gray300;
  flex-shrink: 0;
}

.inputSignalsLeft {
  width: 216px;
  flex-shrink: 0;
  white-space: nowrap;
}

.inputSignalsLeft > :last-child,
.inputSignalsCenter > :last-child {
  margin-left: 8px;
}

.inputSignalsCenter {
}

.inputSignalsRight {
  width: 216px;
}

.inputSignalsLabel {
  margin-right: 8px;
}

.map {
  flex-grow: 1;
  position: relative;
  display: flex;
  height: 100%;
}
