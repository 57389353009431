@value blackPure, whitePure, black200, black100, blue300, gray300 from "../../../styles/colors.module.css";

.root {
  composes: root from "../controls.module.css";

  grid-template-columns: 82px 42px auto 120px 48px;
  grid-template-areas: "route time livepause switch volume";
  grid-column-gap: 4px;
}

.dark {
  composes: dark from "../controls.module.css";
}

.bright {
  composes: bright from "../controls.module.css";
}

.autorepeat {
  grid-area: autorepeat;
}

.autoplay {
  grid-area: autoplay;
}

.route {
  composes: route from "../controls.module.css";
  grid-area: route;
}

.routeButton {
  composes: routeButton from "../controls.module.css";
}

.time {
  composes: time from "../controls.module.css";
  grid-area: time;
}

.playpause {
  grid-area: playpause;
}

.livepause {
  composes: center from "../controls.module.css";
  grid-area: livepause;
}

.previewSwitch {
  grid-area: switch;
}

.volume {
  composes: volume from "../controls.module.css";
  composes: right from "../controls.module.css";
  grid-area: volume;
}
