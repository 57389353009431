.keyHolder {
  display: flex;
  margin-top: -2px;
  margin-bottom: -2px;
  align-items: center;
  width: 100%;
}

.keyHolder > button {
  margin-right: 4px;
  flex-grow: 0;
  flex-shrink: 0;
}

.revealed {
  min-width: 0;
  padding-left: 4px;
  flex-grow: 1;
}
