.pill {
  height: 18px;
  line-height: 18px;
  border-radius: 9px;
  padding: 0 8px;

  font-size: 10px;
  font-weight: 600;
  user-select: none;

  width: min-content;
  display: inline-block;

  white-space: nowrap;

  position: relative;
}

.delete {
  display: block;
  transition: opacity 0.15s ease;
  opacity: 0;
  position: absolute;
  right: 1px;
  top: 2px;
  width: 14px;
  height: 14px;
  border-radius: 9px;
}

.pill:hover .delete {
  opacity: 1;
}

.icon {
  display: block;
  margin-top: -1px;
  margin-left: -1px;
}
