@value gray300 from "../../../styles/colors.module.css";

.routedToLabel {
  font-weight: 600;
  margin-bottom: 8px;
  color: gray300;
}

.signalItem {
  margin: 8px -8px -3px -36px;
}

.status {
  padding-left: 16px;
}

.status div:first-child {
  margin-right: 0;
}

