@value blackPure, whitePure, black200, black100, blue300, gray300 from "../../../styles/colors.module.css";

.root {
  width: 74px;
  height: 24px;
  border-radius: 12px;
  background-color: black100;
  border: none;
  color: gray300;
  font-size: 10px;
  position: relative;
  padding-left: 12px;
}

.root:before {
  position: absolute;
  top: 4px;
  left: 4px;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  background-color: gray300;
  border: 4px solid #36393e;
  border-radius: 8px;
}

.disabled {
  opacity: 0.4;
}
