.root {
  margin-left: 51px;
  position: relative;
}

.switch {
  display: flex;
  align-items: center;
  height: 20px;
  position: absolute;
  left: -51px;
  top: 2px;
}

.container {
  width: 440px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}