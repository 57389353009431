@value gray300, blackPure, whitePure from "../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inputSignalsHeader {
  padding: 0 56px;
  user-select: none;
  height: 32px;
  margin: 13px 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: gray300;
  flex-shrink: 0;
}

.inputSignalsCenter > button {
  margin-left: 8px;
}

.inputSignalsCenter > :first-child {
  margin-left: 0;
}

.inputSignalsCenter {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.inputSignalsLabel {
  margin-right: 8px;
}

.map {
  flex-grow: 1;
  position: relative;
  display: flex;
  height: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.loadingText {
  height: 14px;
  font-size: 10px;
  color: gray300;
  margin-top: 5px;
}
