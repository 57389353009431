@value orange300, black100, black300, gray300 from "../../styles/colors.module.css";

.root {
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.value {
  min-width: 124px;
  line-height: 32px;

  color: gray300;
  border-bottom: 1px solid black100;
}

.lifespan,
.lifespanMin {
  font-weight: 600;
}

.lifespan {
  color: orange300;
}

.text {
  font-weight: 400;
}

.content {
  max-width: 160px;
  margin: 0 16px;
}

.counter {
  max-height: 32px;
}

.counting input,
.counting input:hover {
  color: orange300;
}

.counting div[disabled] {
  opacity: 1;
}

.counting div[disabled] button {
  opacity: 0.4;
}

.disabled .date label,
.disabled .date .value,
.disabled .content label {
  opacity: 0.16;
}
