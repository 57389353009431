.fields {
  padding: 0 30px 0 48px;
  margin-top: 16px;
  height: 530px;
}

.fieldsCustom {
  padding: 0;
  overflow-y: scroll;
  height: calc(100% - 24px);
  padding-top: 16px;
}
