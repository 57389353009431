@value blackPure, whitePure, black200, black100, blue300, gray300 from "../../../styles/colors.module.css";

.root {
  padding-right: 8px;
}

.fullscreen,
.default {
  background-color: transparent;

  position: absolute;
  left: 0;
  right: 0;
}

.fullscreen {
  bottom: 4px;
}

.default {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.dark {
  composes: dark from "../controls.module.css";
}

.bright {
  composes: bright from "../controls.module.css";
}

.volume {
  position: relative;
  bottom: 8px;
  right: 8px;
  composes: right from "../controls.module.css";
  composes: volume from "../controls.module.css";
  grid-area: volume;
}

.default .volume {
  visibility: hidden;
  opacity: 0;
}

.default:hover .volume {
  visibility: visible;
  opacity: 1;
}
