.row {
  display: flex;
  flex-direction: column;
}

.bufferField {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.tooltip {
  margin-left: 6px;
  margin-right: 3px;
  box-sizing: border-box;
  flex-shrink: 0;
  align-self: center;
}