@value black200, whitePure from "../../styles/colors.module.css";

.pill {
  composes: pill from "./index.module.css";
  height: 14px;
  line-height: 14px;
  border-radius: 7px;
  background-color: whitePure;
  color: black200;
}

.delete {
  composes: delete from "./index.module.css";
}
