@value black100 from "../../../../../styles/colors.module.css";

.root {

}

.panelWrapper {
  width: 432px;
  margin: 0 auto;
}

.payload {
  padding: 8px 8px 7px;
  margin-top: 8px;
  border-radius: 2px;
  background-color: black100;
  user-select: text;
  overflow-y: auto;
}

.entityName {
  margin-bottom: 4px;
  line-height: 17px;
}
