@value blackPure80percent, white300 from "../../../styles/colors.module.css";

.root:hover .tooltip {
  display: inline-block;
}

.tooltip {
  display: none;

  top: -100%;
  right: 0;

  border-radius: 2px;

  background-color: blackPure80percent;
  box-sizing: border-box;
  position: absolute;

  z-index: 9999;

  font-size: 10px;
  color: white300;

  min-width: 40px;
  padding: 3px 8px;

  user-select: none;
  pointer-events: none;

  will-change: transform, top, left, display;

  transition: opacity 0.1s ease;
}