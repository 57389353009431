.userInfoLabels {
  display: flex;
}

.userInfoLabels > label {
  margin-right: 16px;
}

.userInfoInputs {
  display: grid;
  grid-template-columns: calc(50% - 16px) 16px 50%;
}
