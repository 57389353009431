@value gray300 from "../../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  flex-direction: column;
  padding: 0 0 56px 0;
  align-items: center;
}

.header {
  user-select: none;
  width: 100%;
  height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray300;
  padding: 0 56px;
  box-sizing: border-box;
}

.headerLabel {
  margin-right: 8px;
}

.stage {
  width: 728px;
  margin: 0 auto;
}

.title {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  margin: 32px 0 14px;
  color: gray300;
}

.graph {
  margin-bottom: 8px;
}

.graph.loading {
  opacity: 0.75;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.infoIcon {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  color: gray300;
}

.exclamationIconCircle {
  fill: gray300;
}

.infoOutgoing {
  padding-bottom: 5px;
  margin-top: 17px;
}

.infoOutgoingText {
  color: gray300;
  font-size: 10px;
}

.columnText {
  font-size: 10px;
}
