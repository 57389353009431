@value white300, black20030percent from "../../../../../../styles/colors.module.css";

.root {
  padding: 0 56px 16px 56px;
  width: calc(100% + 2 * 56px);
  margin-bottom: 8px;
  box-sizing: border-box;
  background-color: black20030percent;
  display: flex;
  flex-direction: column;
  transition: max-height 1s ease, opacity 0.25s ease;

  max-height: 600px;
  overflow: hidden;
  opacity: 1;
}

.root:before {
  display: flex;
  flex-basis: 100%;
  height: 0;
}

.hidden {
  max-height: 0px;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.headline {
  height: 48px;
  line-height: 48px;
  color: white300;
  font-weight: 600;
}

.actions {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 16px;
}

.sendButton {
  margin-left: 8px;
}

.grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
  justify-content: center;
}
