@value black300, white300 from "../../styles/colors.module.css";

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  composes: border-radius box-shadow from "../../comp/index.module.css";
  max-height: 100vh;
  background: black300;

  position: fixed;
  right: 300px;
  z-index: 999999;
  overflow: hidden;

  width: 300px;
  margin-right: 4px;

  animation: show 0.2s ease;
}

.inputContainer {
  width: 100%;
}

.content {
  min-height: 80px;
  max-height: calc(100vh - 48px - 56px);
  overflow: auto;
  padding: 0 16px;
}

.top {
  height: 48px;

  padding: 8px 0;
}

.label {
  color: white300;
  font-weight: 600;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 32px;
  justify-content: space-between;
}

.labelText {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label .status {
  margin-right: 4px;
  margin-left: -6px;
  display: flex;
  align-items: center;
}

.label .avatar {
  margin-right: 8px;
  display: flex;
}

.buttonContainer {
  text-align: right;
  padding: 8px 16px 16px 16px;
}

.withIcon {
  display: flex;
  align-items: center;
}

.labelIcon {
  margin-left: 8px;
  display: flex;
}

.labelLeftIcon {
  margin-right: 8px;
  display: flex;
}

.twoButtonContainer {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.confirmMessage {
  text-align: center;
}

.confirmMessage span {
  font-weight: 600;
}

.messageIcon {
  width: 14px;
  height: 14px;
  display: inline-flex;
  position: relative;
}

.messageIcon span {
  position: absolute;
  left: 0px;
}