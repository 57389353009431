.root {
  display: grid;
  justify-content: center;
  align-content: start;
}

.loading {
  display: flex;
  align-items: center;
  height: 100%;
}
