@value white300, white500, black300, black400, black100, black200, gray300 from "../../styles/colors.module.css";
.root {
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.bright {
  background-color: black300;
}

.dark {
  background-color: black200;
}

.fullscreen {
  background-color: black100;
  border-radius: 2px;
  margin-right: 8px
}

.headline {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pill {
  font-weight: 600;
}

.divider {
  width: 1px;
  height: 17px;
  background-color: black400;
  display: block;
  border-radius: 1px;
  margin-inline: 4px;
}

.signalDescription {
  padding-right: 4px;
  color: white300;
  font-weight: 600;
}

.sourceIndicator {
  display: flex;
  gap: 6px;
  align-items: center;
  color: white500;
  font-weight: 600;
}

.sourceIndicator span:last-child {
  line-height: 17px;
}

.root .actions {
  display: flex;
  align-items: center;
  gap: 4px;
}

.actions {
  display: flex;
  align-items: center;
}

.selectedSource {
  padding-inline: 8px;
  color: white300;
}

.selectedSource span {
  font-weight: 400;
}

.action {
  margin-right: 4px;
}

.buttonTooltip {
  position: absolute;
  right: 8px;
}