@value gray300 from "../../../styles/colors.module.css";

.thumb {
  color: gray300;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
}

.svg {
  display: flex;
  align-items: center;
  fill: gray300;
  height: 32px;
  opacity: 0.4;
}
