.root {
  position: relative;
  box-sizing: border-box;
}

.root path {
  transition: fill 0.1s ease;
}

.clearButton {
  display: none;
}

.root.open .clearButton {
  display: inline-block;
}
