@value green300, red300, blue100, gray300, gray10030Percent, gray10050Percent, white300, black200, black300, red20030Percent, red20040Percent, red20050Percent from "../../styles/colors.module.css";

.root {
  composes: border-radius from "../index.module.css";
  box-sizing: border-box;

  /* defaults */
  height: 65px;
  padding: 12px 8px;

  background-color: black200;

  user-select: none;

  border: 1px solid transparent;
  outline: none;
  color: gray300;
  fill: gray300;

  transition: border-color 0.075s ease, background-color 0.075s ease, color 0.1s ease;
}

/* from list item */

.root:not(:disabled):hover {
  background-color: gray10050Percent;
}

.root:not(:disabled):not(:hover):focus {
  background-color: gray10030Percent;
}

.root:active,
.selected {
  border-color: blue100;
  background-color: black300;
}

.root:active,
.root:focus,
.selected,
.root:not(:disabled):hover {
  color: white300;
}

.root:active path,
.root:focus path,
.selected path,
.root:not(:disabled):hover path {
  fill: white300;
}

.erroneous {
  background-color: red20030Percent;
}

.erroneous:not(:disabled):hover {
  background-color: red20050Percent;
}

.erroneous:not(:disabled):not(:hover):focus {
  background-color: red20040Percent;
}

.erroneous:active,
.erroneous .selected {
  border-color: blue100;
  background-color: red20050Percent;
}

/* withStatus */
.statusWrapper {
  display: flex;
}

.statusIconWrapper {
  width: 100%;
}

.statusIcon {
  margin-right: 8px;
}

.statusTitle {
  max-width: calc(100% - 34px);
  white-space: nowrap;
  width: calc(100% - 34px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusIconDescription {
  margin-left: 8px;
  font-size: 10px;
}

.statusDescription {
  margin-top: 2px;
  font-size: 10px;
}
