@value white300, black100 from "../../../styles/colors.module.css";

.title {
  padding-left: 48px;
}

.title p {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  color: white300;
  margin-bottom: 5px;
}

.titleSeparator {
  width: 518px;
  height: 1px;
  background-color: black100;
}
