@value black200, black300from "../../../../styles/colors.module.css";

.hexagon {
  background-image: url("./img/special-hexagon.svg");
  background-size: cover;
}

.root {
  display: flex;
  align-items: center;
  max-height: fit-content;
}

.root[disabled] {
  opacity: 1;
}

.title {
  background: black200;
  height: 56px;
}

.statusMessage,
.dataColumns {
  min-width: 64px;
  margin-right: 16px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.statusMessage {
  white-space: nowrap;
}

.column {
  line-height: normal;

  min-width: 80px;
  height: 32px;
  margin-left: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  justify-content: center;
}

.column > * {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.labelWithIcon {
  display: flex;
  align-items: center;
}

.labelIcon {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.dataColumns {
  margin-left: auto;
}
