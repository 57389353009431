@value white300, blue100, red500, black300, black200, red50050Percent, green300, green20030Percent, blackPure, gray300 from "../../../styles/colors.module.css";

.root {
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
}

.inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100%);
  background-color: black200;
  border-radius: 2px;
  height: 56px;
  align-items: center;
}

.inner:hover {
  background-color: black300;
}

.thumbnail {
  grid-area: media;
  position: relative;
  height: 56px;
  width: 101px;
  background-color: blackPure;
  flex-shrink: 0;
}

.inner:hover .thumbnail .overlay {
  opacity: 1;
  visibility: visible;
}

.overlay {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(250, 250, 250, 0.2);
}

.media {
  display: flex;
  width: 101px;
  height: 56px;
}

/*.input {
  padding: 8px;
  display: flex;
  justify-content: space-between;

  height: 32px;
}

.inputInfo {
  display: flex;
  align-self: flex-end;
}

.inputInfoItems {
  margin-left: 8px;
}
*/

.highlighted {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  border: 1px solid red500;
  box-shadow: 0 0 0 4px red50050Percent inset;
  height: 56px;
}

.selected:after {
  content: "";
  border-radius: 2px;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid #2d70c8;
  pointer-events: none;
}
