@value blackPure80percent, white300,white30080percent from "../../styles/colors.module.css";

.strategyIndicator {
  composes: border-radius from "../index.module.css";
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: blackPure80percent;
  border: 1px solid white30080percent;
  color: white300;
  padding: 1px 3px;
  opacity: 0.5;
  font-size: 10px;
  line-height: 11px;
}
