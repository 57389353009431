@value black100, black300, gray300, white300 from "../../../../styles/colors.module.css";

.root {
  user-select: none;
  color: white300;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  flex-grow: 1;
}

.datePickerContainer {
  margin: 22px -16px 0 -16px;
}

.expiration {
  font-size: 10px;
  color: gray300;

  display: flex;
  align-items: center;
  padding-top: 6px;
}

.days {
  font-weight: 600;
}
