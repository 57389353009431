@value black500, black100, green20030Percent from "../../styles/colors.module.css";
.root {
  padding: 11px 0 0 16px;
  user-select: none;
}

.topbar {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  padding-right: 16px;
}

.buttonEdit {
  margin-left: 8px;
}

.users {
  margin-bottom: 8px;
}

.title {
  font-size: 12px;
  line-height: 18px;
  color: black500;

  padding-bottom: 4px;
  margin: 0 -16px 8px 0;
  border-bottom: 1px solid black100;
}
