@value blackPure, whitePure, red500, red50050Percent from "../../../styles/colors.module.css";

.marker,
.plain {
  position: relative;
  width: 34px;
  height: 43px;
  margin-top: -40px;
  margin-left: -17px;
  cursor: pointer;
  user-select: none;
  z-index: 500;
}

.inner {
  position: absolute;
  top: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  color: blackPure;
  max-width: 0px;
  height: 28px;
  border-radius: 14px;
  user-select: none;
  box-sizing: border-box;
  margin-left: 0;
  padding-left: 18px;
  transition: max-width 0.15s ease-in-out, padding 0.15s ease-in-out;
  border: 5px solid whitePure;
}

.innerLabel {
  display: flex;
  align-items: center;
  background-color: whitePure;
  line-height: 18px;
  transition: max-width 0.15s ease-in-out, padding 0.15s ease-in-out;
  overflow: hidden;
}

.marker.selected .inner,
.marker:hover .inner {
  max-width: 250px;
  padding-left: 14px;
}

.marker.selected .innerLabel,
.marker:hover .innerLabel {
  padding-left: 14px;
  padding-right: 10px;
}

.routed .inner:before {
  content: "";
  position: absolute;
  display: block;
  width: 34px;
  height: 34px;
  top: -8px;
  left: -8px;
  border-radius: 17px;
  border: 1px solid red500;
  box-shadow: inset 0 0 0 2px red50050Percent;
  box-sizing: border-box;
  transition: width 0.05s ease-in-out;
}

.routed.selected .inner:before,
.routed:hover .inner:before {
  width: calc(100% + 16px);
}

.routed .innerLabel:before {
  content: "";
  position: absolute;
  left: 5px;
  bottom: -13px;
  width: 1px;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 10px solid whitePure;
}

.pointer {
  position: relative;
  text-align: center;
  height: 43px;
}

.pointerImg {
  width: 34px;
  height: 43px;
}

.avatar {
  position: absolute;
  right: 5px;
  bottom: 14px;
  width: 24px;
  height: 24px;
}

.avatar > div {
  border-radius: 12px !important;
}

.avatar img,
.avatar svg {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.status {
  position: absolute;
  top: 11px;
  left: 11px;
}

.location {
  line-height: 17px;
  font-size: 10px;
  white-space: nowrap;
  align-items: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location svg {
  margin-right: -3px;
  margin-left: -5px;
  flex-shrink: 0;
}

.location path {
  fill: black500;
}

.locationOnly {
  line-height: 32px;
  font-size: 12px;
}
