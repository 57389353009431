@value black400, gray300 from "../../../styles/colors.module.css";
.root {
  display: flex;
  flex-direction: column;
  margin-left: -16px;
  margin-bottom: -8px;
}

.metrics {
  display: flex;
  flex-wrap: wrap;
  background-color: black400;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  margin-right: -16px;
}

.graph {
  width: 268px;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.graph:not(:last-child) {
  margin-right: 16px;
}
