@value gray300 from "../../../styles/colors.module.css";
.metadata {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.content {
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  margin-right: 16px;
  height: 68px;
  width: 268px;
}

.label {
  color: gray300;
  font-weight: 600;
  width: 88px;
}

.data {
  flex-grow: 1;
}
