@value blue100 from "../../styles/colors.module.css";

.sortable {
}

.item {
  cursor: grab;
}

.over {
  box-sizing: border-box;
}

.over.overTop {
  border-top: 1px solid blue100;
}

.over.overBottom {
  border-bottom: 1px solid blue100;
}

.selected {
  opacity: 0.4;
}
