@value black100, black200, white300, gray300 from "../../styles/colors.module.css";

.root {
  width: 100%;
  height: 64px;
  min-height: 64px;
  background-color: black100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 0 20px;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.navigation button {
  margin-right: 8px;
}

.navigationButtonActive {
  background-color: black200 !important;
}

.topbarLeft {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 8px;
}

.teamName {
  color: gray300;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  user-select: none;
}

.topbarPipe,
.topbarRightPipe {
  width: 1px;
  height: 32px;

  composes: border-radius from "../../comp/index.module.css";
  border-right: 1px solid #34383d;

  margin-left: 16px;
  margin-right: 16px;
}

.topbarRight {
  display: flex;
  margin-left: auto;
}

.userMenu button span span:first-child[size-h="default"] {
  padding: 0;
  margin-right: 8px;
  margin-left: 4px;
}
