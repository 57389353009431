@value whitePure, blackPure from "../../styles/colors.module.css";

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(0, 64px);
  }
}

.alert {
  composes: border-radius box-shadow from "../index.module.css";

  box-sizing: border-box;

  width: 380px;
  padding: 16px 16px 8px 16px;
  height: auto;
  background-color: whitePure;

  display: flex;
  flex-direction: column;
  align-items: center;

  user-select: none;

  will-change: opacity, transform, filter, blur;

  transform: translateZ(0);
  filter: blur(0px);

  animation: show 0.2s;
}

.alertFadingOut {
  animation: hide 0.2s ease-out;
}

.alertTitle {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.38;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.alertMessage {
  user-select: text;
  text-align: center;
}

.alertTitle,
.alertMessage {
  color: blackPure;
}

.alertButtons {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.alertButtonClose {
  margin-right: 16px;
}
