@value blackPure, black200, black300, black400, black100, gray300 from "../../styles/colors.module.css";

.root {
  display: flex;
  position: relative;
  scroll-behavior: smooth;
}

.container {
  padding-right: 16px;
}

.open,
.closed {
  background-color: black200;
  height: calc(100vh - 65px);
  width: 300px;

  flex-grow: 1;
  flex-shrink: 0;

  transition: width 0.25s ease;

  display: flex;
  flex-direction: column;
}

.closed {
  width: 0 !important;
  overflow: hidden;
}

.buttonLeftOpen,
.buttonRightOpen,
.buttonLeftClosed,
.buttonRightClosed {
  composes: border-radius from "../index.module.css";
  padding: 0;
  line-height: 0;
  background-color: black300;
  box-sizing: border-box;
  border: none;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 12px;
  z-index: 9999;

  height: 32px;
  width: 20px;

  transition: margin 0.25s ease;
}

.buttonLeftOpen {
  left: 100%;
  margin-left: 4px;
}

.buttonRightOpen {
  right: 100%;
  margin-right: 4px;
}

.buttonLeftClosed {
  left: 100%;
  margin-left: 8px;
}

.buttonRightClosed {
  right: 100%;
  margin-right: 8px;
}

.buttonIcon {
  transition: transform 0.25s ease;
}

.buttonLeftClosed .buttonIcon,
.buttonRightClosed .buttonIcon {
  transform: rotate(-180deg);
}

.contentContainer {
  box-sizing: border-box;
  width: 300px;
  flex-grow: 1;
  overflow-y: hidden;
}
