@value blackPure, black100, red500, red50050Percent from "../../styles/colors.module.css";

@keyframes shimmer {
  0% {
    background-color: blackPure;
  }

  50% {
    background-color: black100;
  }

  100% {
    background-color: blackPure;
  }
}

.root {
  position: relative;
  background-color: blackPure;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.loading {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.loadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 267px);
  display: block;
}

.img {
  background-color: blackPure;
  width: 100%;
  height: auto;
}

.highlighted {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  border: 1px solid red500;
  box-shadow: 0 0 0 4px red50050Percent inset;
}

.contain {
  object-fit: contain;
}
