@value black300, red200 from "../../../../styles/colors.module.css";

.root {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  background-color: black300;
  border-radius: 2px;
}

.root:last-child {
  margin-bottom: 0;
}

.error {
  /* since outline doesn't support radius */
  box-shadow: 0 0 0 1px red200;
}

.inputsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  flex-grow: 1;
}

.divider {
  display: flex;
  align-items: center;
  margin: 0 4px;
  flex-shrink: 0;
  width: 14px;
}

.inputSource,
.inputTarget {
  flex-grow: 1;
  flex-shrink: 0;
}

.removeButton {
  display: flex;
  align-items: center;
  position: absolute;
  right: -32px;
}
