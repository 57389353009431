.lineSeparatorDefault {
  margin-right: -71px;
}

.lineSeparatorOverride {
  margin-right: -39px;
}

.addBtnHolder button[size-h="default"][has-icon] {
  padding: 0 16px 0 8px;
}

.addBtnHolder button > span > span[size-h="default"] {
  padding: 0;
}
