@value gray300 from "../../../../styles/colors.module.css";

.resourceLocationsContainer {
  padding-right: 70px;
}

.valueRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleSubtitle {
  display: inline-block;
}

.expansionTitleMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  line-height: 10px;
}

.expansionTitleSub {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;
  padding-left: 24px;
}

.expansionTitleSub p {
  font-size: 10px;
}

.value {
  background-color: #34383d;
  color: gray300;
  line-height: 24px;
  height: 18px;
  margin: auto 0;
}

.value:focus {
  outline: none;
  border: none;
}

.rtmpUrlCopy,
.rtmpStreamnameCopy {
}
