@value white300 from "../../../styles/colors.module.css";

.title {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
}

.label {
  color: white300;
  text-overflow: ellipsis;
  overflow: hidden;
}

.topbarIcons {
  display: flex;
}

.topbarIcons > * {
  margin-left: 8px !important;
}
