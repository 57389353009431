@value white300, blue100, red500, black300, black200, red50050Percent, green300, green20030Percent, blackPure from "../../../styles/colors.module.css";

.root {
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: black200;
  border-radius: 2px;
}

.thumbnail {
  position: relative;
  height: 202px;
  background-color: blackPure;
}

.thumbnail:hover .overlay {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s ease-in-out;
}

.overlay {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(250, 250, 250, 0.2);
  transition: opacity 0.25s ease-in-out;
}

.media {
  max-width: 100%;
  height: 100%;
}

.input {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.label {
  display: flex;
  align-self: flex-start;
  user-select: none;
  max-width: calc(100% - 32px);
  min-width: 0;
}

.label div[size-v="medium"] {
  margin-bottom: 0;
}

.inputInfo {
  display: flex;
  align-self: flex-end;
}

.inputInfoItems {
  margin-left: 8px;
}

.content {
  padding: 0 8px 8px;
  background: black200;
}

.highlighted {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  border: 1px solid red500;
  box-shadow: 0 0 0 4px red50050Percent inset;
}

.selected:after {
  content: "";
  border-radius: 2px;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 1px solid #2d70c8;
  pointer-events: none;
}
