.segmentOptionsRow {
    display: flex;
    flex-direction: row;
  }
  
.segmentOption {
  width: 188px;
  display: flex;
  flex-direction: column;
}

.segmentOption:first-child {
  margin-right: 16px;
  width: 172px;
}

.segmentOptionInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tooltip {
  margin-left: 4px;
}