@value black100, gray300 from "../../../styles/colors.module.css";

.root {
  composes: root from "../index.module.css";
}

.map {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black100;
  box-sizing: border-box;
  color: gray300;
}
