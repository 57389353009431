@value gray300, blue100, white300 from "../../../styles/colors.module.css";

.tabs {
  margin-top: 56px;
  margin-bottom: 16px;
}

.tabs ol {
  padding-left: 16px;
}

.tabs li {
  height: 32px;
  line-height: 32px;
  font-weight: 600;
  color: gray300;
  margin: 0 auto 8px 17px;
  box-sizing: border-box;
  position: relative;
}

.tabs li:first-child {
  margin-top: 0;
}

.tabs li.tabActive {
  color: white300;
}

.tabActive:before {
  border-radius: 2px;
  content: "";
  border: 1px solid blue100;
  margin-left: -32px;
  padding-right: 32px;
  margin-top: -1px;
  position: absolute;
  height: 100%;
  width: 100%;
}

.iconWrapper {
  max-width: 56px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  position: absolute;
  right: 2px;
  top: 2px;
}

.errorIcon,
.warningIcon {
}
