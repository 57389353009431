@value blackPure, blackPure80percent, white300, blue200, red500, red50050Percent from "../../styles/colors.module.css";
@value gray300, gray10030Percent, whitePure from "../../styles/colors.module.css";

.tileRouting {
  width: 82px;
  height: 24px;
  border-radius: 12px;
  align-self: flex-start;
  color: gray300;
  background-color: blackPure80percent;
  line-height: 24px;
  font-size: 10px;
  display: flex;
  user-select: none;
  transition: background-color 0.1s ease-out, color 0.1s ease-out, opacity 0.1s ease;
}

.tileRouting:not(.disabled):hover,
.tileRouting:not(.disabled):active {
  color: whitePure;
  background-color: blackPure;
}

.tileRoutingOuterCircle {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
  margin-right: 10px;
  background-color: gray10030Percent;
  border-radius: 8px;
}

.tileRoutingOuterCircle {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
  margin-right: 10px;
  background-color: gray10030Percent;
  border-radius: 8px;
}

.tileRoutingInnerCircle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: gray300;
  display: block;
  margin-left: 4px;
  margin-top: 4px;
}

.disabled {
  opacity: 0.4;
}
