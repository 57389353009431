@value black200 from "../../../styles/colors.module.css";
.takers {
  margin-bottom: 8px;
  flex-direction: row !important;
  display: flex;
  flex-wrap: wrap;
}

.graph {
  margin-right: 0 !important;
}

.root {
  margin-bottom: 0 !important;
  margin-top: 8px;
  margin-left: 0 !important;
}

.takersHeadline {
  margin-bottom: 12px;
}

.taker {
  width: 268px;
  box-sizing: border-box;
  margin-right: 16px;
  margin-bottom: 8px;
  position: relative;
}

.flyout {
  margin-bottom: 4px;
  margin-right: 0;
}

.flyoutTakers {
  display: flex;
  flex-direction: column;
}

.flyout:last-child {
  margin-bottom: 0;
}

.takersHeadline {
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 16px;
}

.metrics {
  margin-left: -16px;
}

.metadata:first-child {
  border-top: none;
}

.metadata {
  border-top: 1px solid black200;
  width: 100% !important;
  margin-right: 0 !important;
  height: auto !important;
  padding: 7px 0 !important;
  box-sizing: border-box !important;
}

.takersHeadlineFlyout {
  margin-top: 8px;
}
