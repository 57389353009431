@value blackPure, gray300, blackPure80percent from "../../styles/colors.module.css";

.stage,
.inspector {
}

.inspector,
.inspector * {
  cursor: default;
}

.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 2px;
  background-color: blackPure;
  color: gray300;
  padding: 0 6px 0 2px;
  box-sizing: border-box;
  height: 20px;
  white-space: nowrap;
  max-width: 96%;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: blackPure80percent;
  display: flex;
}
