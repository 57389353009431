.disabled {
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}

.header {
  display: flex;
}

.headerDivider {
  width: 22px;
}

.headerColumn {
  flex-grow: 1;
}
