@value gray300 from "../../../../../styles/colors.module.css";

.roleItem {
  display: flex;
  flex-direction: column;
}

.roleItemName {
  font-weight: 600;
  margin-bottom: 4px;
}

.roleItemPolicies {

}

.dropdownRole {
  padding: 8px 0;
}

.dropdownRoleName {
  line-height: 17px;
  margin-bottom: 6px;
}

.dropdownRolePolicies {
  line-height: 17px;
  color: gray300;
  white-space: normal;
}

.dropdownPolicy {
  display: inline-flex;
  align-items: center;
  margin-right: 2px;
  gap: 2px;
}

.dropdownPolicy:last-child {
  margin-right: 0;
}

.roleCard {
  position: relative;
  margin-top: 8px;
  padding: 12px;
}

.roleCard .roleItem+.roleRemoveBtn {
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translate(8px, -50%);
}