@value gray300 from "../../../../styles/colors.module.css";

.content {
}

p.contentHeader {
  height: 32px;
  line-height: 32px;
}

.logsContainer {
  height: 604px;
  overflow-y: scroll;
  margin-top: 16px;
}

.noLogsContainer {
  width: 188px;
  color: gray300;
  text-align: center;
}

.noLogsHeading {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.noLogsPlaceholder {
  margin-bottom: 20px;
}
