@value blackPure, black100, gray300 from "../../styles/colors.module.css";

.root {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99998;

  display: flex;
  justify-content: center;
  align-items: center;

  background: blackPure;
}

.closeButton {
  position: absolute;

  top: 8px;
  right: 8px;

  z-index: 99999;

  display: flex;
  align-items: center;
}

.root video {
  max-height: 100%;
}

.loadingLabelContainer {
  color: gray300;
  background: black100;
  margin-right: 8px;
  display: flex;
  align-items: center;
  height: 32px;
  composes: border-radius from "../../comp/index.module.css";
  padding: 0 16px 0 4px;
}

.loadingLabelContainer > svg {
  margin-right: 8px;
}
