@value black200 from "../../../styles/colors.module.css";
.graph {
  margin-right: 0 !important;
}

.root {
  margin-top: 8px;
  margin-bottom: 0 !important;
  margin-left: 0;
}

.metrics {
  margin-left: -16px;
}

.metadata:first-child {
  border-top: none;
}

.metadata {
  border-top: 1px solid black200;
  width: 100%;
  margin-right: 0;
  height: auto;
  padding: 7px 0;
  box-sizing: border-box;
}
