@value black100, black300, gray300, white300 from "../../../../styles/colors.module.css";

.root {
  user-select: none;
  color: white300;
}

.topbar {
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.title {
  flex-grow: 1;
}

.buttonReset {
  margin-right: 8px;
}

.filter {
  margin-left: 16px;
  padding: 12px 16px 12px 0;
  border-bottom: 1px solid black100;
}

.infoContainer {
  margin-top: 6px;
  font-size: 10px;
}

.infoText {
  color: gray300;
}

.infoText span {
  font-weight: 600;
}

.datePickerWrapper {
}

.datePickerWrapper > div:first-child {
  position: static;
}
