@value black200, black300, black400, black500, gray300, white300, blue100 from "../../styles/colors.module.css";
.root {
  composes: border-radius from "../../comp/index.module.css";

  width: 100%;
  font-weight: 600;
  padding: 4px;
  height: 32px;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  background-color: black300;
  border: 1px solid transparent;

  outline: none;
}

.selected {
  border-color: blue100;
  background-color: black400;
}

.selected .label {
  color: white300;
}

.root:hover .label {
  color: white300;
}

.inactive {
  composes: root;
}

.inactive:not(:disabled):hover,
.inactive:not(:disabled):active {
  background-color: black500;
  outline: none;
}

.inactive:not(:disabled):not(:hover):focus {
  background-color: black400;
  outline: none;
}

.active {
  composes: root;
  flex-direction: row;
  padding: 11px 7px 7px 7px;
  height: 65px; /* height is 222px for graph */
}

.col {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.activeTitle {
  height: 24px;
  display: flex;
  flex-direction: row;
}

.activeTitleIcons {
  height: 24px;
  display: flex;
  flex-direction: row;
}

.activeIcons {
  width: 100%;
  height: 24px;
  margin-top: -7px;
}

.activeIcons path {
  fill: gray300;
}

.qualityIcon {
}

.platformIcon {
  display: inline-flex;
  color: white300;
  align-items: center;
  margin-left: -4px;
}

.graphs {
  margin-top: 11px;
}

.bandwidthGraph,
.fpsGraph {
  width: 100%;
  height: 71px;
}

.fpsGraph {
  margin-top: 8px;
}

.label {
  display: inline-flex;
  height: 24px;
  line-height: 24px;
  margin: 0 4px;
  flex-grow: 1;
  overflow: hidden;
  color: gray300;
  text-overflow: ellipsis;
}
