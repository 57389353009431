@value gray300, blackPure, whitePure from "../../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 56px 56px 56px;
  align-items: center;
}

.header {
  user-select: none;
  width: 100%;
  height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: gray300;
}

.headerLeft {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerMembersLabel {
  margin-right: 8px;
}

.headerMembersPill {
  margin-right: 16px;
}

.headerInviteField {
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  margin-left: 16px;
  flex-flow: column-reverse;
}

.stage {
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  justify-content: center;
}

.segmented {
  display: flex;
  flex-direction: column;
}

.segmentTitle {
  margin: 3px 4px 0 0;
  font-size: 12px;
  color: gray300;
  display: flex;
  gap: 4px;
}

.gap {
  margin-top: 32px;
}

.segmentContent {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.wrapper {
  display: flex;
}

.itemName {
  font-weight: 600;
  margin-bottom: 4px;
}

.itemDetail {
  display: flex;
  margin-top: 2px;
  justify-content: space-between;
  align-items: flex-end;
}
