@value gray300, black200 from "../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 56px 56px 56px;
  align-items: center;
}

.header {
  user-select: none;
  width: 100%;
  height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray300;
}

.headerLabel {
  margin-right: 8px;
}

.stage {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.group {
  margin-bottom: 16px;
}

.group > div {
  margin-bottom: 8px;
}

.groupHeader {
  width: 100%;
  color: gray300;
  height: 18px;
  padding-bottom: 4px;
  border-bottom: 1px solid black200;
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
