@value black100, white300, blue300, black200, green500, red300, orange300 from "../../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: column;
}

.meta {
  margin-right: -8px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black100;
  border-top: 1px solid black100;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 8px;
  line-height: 30px;
  box-sizing: border-box;
}

.stateIcon {
  margin-right: 10px;
}

.metaLabel {
  font-weight: 600;
  color: white300;
  margin-right: 4px;
}

.pill {
  background-color: blue300 !important;
  color: black200 !important;
}

.sourceIcon {
  margin-right: 0 !important;
}

.sourceItem {
  margin-bottom: 0 !important;
}

.ready {
  color: green500 !important;
}

.buttonActivate {
  margin-left: auto;
}
