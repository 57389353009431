.statusIcons {
  margin-left: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-right: 4px;
}

.statusIcons > * {
  flex-shrink: 0;
}

.alertIcon {
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
