@value white300, black300, gray300 from "../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: column;
}

.topbarContainer {
  width: 100%;
}

.player {
  margin-bottom: 4px;
}

.topbar {
  display: flex;
  flex-shrink: 0;
  align-items: center;

  padding: 12px 16px;
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  justify-content: space-between;
}

.title {
  font-size: 12px;
  font-weight: 600;
  color: white300;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.innerContent {
  display: contents;
}

.map {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mapview,
.placeholder {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.mapview {
  min-height: 300px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid {
  padding: 0 16px;
}

.user {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.user span {
  font-size: 12px;
  color: gray300;
  margin-left: 8px;
}

.placeholder {
  padding: 0 16px;
  display: flex;
  align-content: flex-start;
  box-sizing: border-box;
}

.input {
  width: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  margin-inline: 16px;
}

.inputName {
  color: gray300;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.inputType {
  font-size: 10px;
}
.previewWarning {
  background-color: black300;
  padding: 0 8px 8px;
}