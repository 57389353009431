@value gray300 from "../../../../styles/colors.module.css";

.root {
  margin: 15px 16px;
}

.title {
  margin-bottom: 15px;
  color: gray300;
  font-weight: 600;
}
