@value black200, black300 from "../../styles/colors.module.css";

.root {
  composes: root from "./index.module.css";
  composes: border-radius from "../index.module.css";
  max-height: fit-content;
}

.root[disabled] {
  opacity: 1;
}

.icon {
  composes: icon from "./index.module.css";
  flex-shrink: 0;
}

.root[disabled] .icon {
  opacity: 0.4;
  pointer-events: none;
}

.title {
  composes: title from "./index.module.css";
  background: black200;
  height: 56px;
}

.rootExpanded {
  composes: rootExpanded from "./index.module.css";
}

.rootExpanded .title {
  background: black300;
}
