@value whitePure, gray200, white300, white500, black500 from "../../styles/colors.module.css";

.slider {
  composes: root from "./index.module.css";
  background-color: gray200;
  color: white300;
}

.handle {
  composes: handle from "./index.module.css";
  background-color: white500;
}

.handle:hover,
.slider:focus .handle,
.slider:active .handle {
  box-shadow: 0 1px 2px 0 rgba(27, 27, 27, 0.8);
  background-color: whitePure;
}

.min {
  composes: min from "./index.module.css";
  color: black500;
}

.max {
  composes: max from "./index.module.css";
  color: black500;
}
