@value black200, gray300 from "../../styles/colors.module.css";

.pill {
  composes: pill from "./index.module.css";
  background-color: black200;
  color: gray300;
}

.delete {
  composes: delete from "./index.module.css";
}
