.root {
  composes: root from "../index.module.css";
}

.title {
  composes: title from "../index.module.css";
  margin-bottom: 8px;
}

.message {
  composes: message from "../index.module.css";
  max-width: 268px;
}

.icon {
  composes: icon from "../index.module.css";
  width: auto;
  margin-bottom: 33px;
}

.wrapper {
  composes: wrapper from "../index.module.css";
}
