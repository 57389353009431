@value gray300, black100 from "../../../styles/colors.module.css";

.lineSeparator {
  background: black100;
  height: 1px;
}

.subtitle {
  line-height: 20px;
  color: gray300;
}

.rowSwitchContainer {
  display: grid;
  grid-template-columns: 48px auto;
}

.rowSwitchContainer > div:first-child {
  padding-top: 14px;
}

.rowIconContainer {
  display: grid;
  grid-template-columns: 48px 440px 30px;
  align-items: center;
}

.rowIconContainer > div:last-child {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rowSwitchIconContainer {
  display: grid;
  grid-template-columns: 48px 440px 32px;
}

.rowSwitchIconContainer > div:first-child {
  padding-top: 16px;
}

.rowSwitchIconContainer > div:last-child {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 65px;
}

.expansionTitleMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray300;
}

.expansionTitleMain button {
  margin-right: 10px;
}

.expansionTitleSub {
  color: gray300;
  height: 14px;
  font-size: 10px;
  margin-left: 25px;
}

.expansionContentContainer {
  width: 360px;
  margin-left: 25px;
  padding-bottom: 32px;
}

.sliderContainer {
  display: flex;
}

.sliderContainer > input {
  margin-right: 5px;
}
