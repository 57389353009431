@value blackPure, whitePure, black200, black100, black300, blue300, gray300 from "../../styles/colors.module.css";

.root {
  height: 32px;
  background-color: black200;
  display: grid;
  align-items: center;
  padding: 0 6px 0 8px;
  box-sizing: border-box;
  position: relative;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dark {
  background-color: black200;
}

.bright {
  background-color: black300;
}

.time {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: gray300;
}

.volume {
  position: relative;
  display: flex;
  align-items: center;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
  justify-content: end;
}
