@value gray300, black400, black300, white300 from "../../../styles/colors.module.css";

.list {
  composes: border-radius from "../../../comp/index.module.css";
  background-color: black400;
  padding: 7px;
  color: gray300;
  user-select: none;
  display: flex;
  flex-direction: row;
  height: 32px;
}

.list.dark {
  background-color: black300;
  color: white300;
}

.iconImg {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  margin: -3px 3px -3px -3px;
}

.iconComp {
  margin-right: 3px;
}
