@value black100, black200, black300, gray300, whitePure from "../../styles/colors.module.css";

.graph {
  composes: border-radius from "../../comp/index.module.css";
  position: relative;
  background-color: black200;
  width: 100%;
  height: 71px;
  box-sizing: border-box;
  padding: 4px 8px 8px 4px;
  color: gray300;
}

.graphTitlebar {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 2px 0 6px 4px;
  justify-content: space-between;
  align-items: center;
}

.graphTitle {
  flex-grow: 1;
  font-weight: 600;
  user-select: none;
}

.graphCurrentValueContainer {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
}

.graphCurrentValue {
  margin-right: 3px;
}

.value {
  color: whitePure;
}

.overlay {
  position: absolute;
  top: 37px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.fillWhitePure {
  fill: whitePure;
}

.fillBlack100 {
  fill: black100;
}
