@value white300, black100, blackPure from "../../../../styles/colors.module.css";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description {
  padding-left: 48px;
  padding-right: 76px;
}

.descriptionSeparator {
  margin-left: 48px;
}

.content {
  padding-left: 48px;
  padding-right: 76px;
}

.select {
  display: flex;
  gap: 16px;
  align-items: center;
}
