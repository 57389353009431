@value gray300, black300 from "../../styles/colors.module.css";

.footerContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.footerRouteContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footerRouteContainer > div:first-child {
  flex: 1 0 auto;
  padding-bottom: 20px;
}

.lvcText {
  color: gray300;
  padding: 0 16px;
}

.policyLink {
  white-space: nowrap;
  padding: 0 16px;
}

.policyLinkWithBorder {
  composes: policyLink;
  border-right: 1px solid black300;
}

.lvcFooterLogo {
  padding: 0 16px;
  fill: gray300;
  border-right: 1px solid black300;
}

@media screen and (max-height: 760px) {
  .footerRouteContainer {
    overflow-y: auto;
  }
}

@media screen and (max-width: 980px) {
  .lvcText {
    display: none;
  }
}
