@value gray300, blackPure, whitePure from "../../styles/colors.module.css";

.root {
  margin: 0 auto;
  padding: 0 35px;
  height: calc(100vh - 192px);
  display: flex;
  flex-direction: column;
}

.header {
  user-select: none;
  height: 32px;
  margin: 13px 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: gray300;
  flex-shrink: 0;
}

.headerLeft {
  width: 50px;
  flex-shrink: 0;
}

.headerLeft > :last-child,
.headerCenter > :last-child {
  margin-left: 8px;
}

.headerCenter {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-right: 50px;
}

.source {
  width: calc(100% - 35px);
  margin: 0 auto;
  box-sizing: border-box;
}

.source video {
  background: blackPure;
}

.inputProductions {
  margin-top: 40px;
}

.routingCanvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  pointer-events: none;
  display: none;
}

@keyframes hideRoutingCanvas {
  0%,
  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
