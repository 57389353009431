@value gray300, blackPure, whitePure from "../../../styles/colors.module.css";

.root {
  margin: 0 auto;
  padding: 0 35px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  user-select: none;
  height: 32px;
  margin: 13px 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: gray300;
  flex-shrink: 0;
}

.headerLeft {
  width: 50px;
  flex-shrink: 0;
}

.headerLeft > :last-child,
.headerCenter > :last-child {
  margin-left: 8px;
}

.headerCenter {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-right: 50px;
} 

.source video {
  background: blackPure;
}

.inputProductions {
  margin-top: 40px;
}
