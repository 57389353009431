.root {
    margin-left: 51px;
}

.header {
    font-size: 12px;
}

.urlContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.urlInput {
    flex-grow: 1
}

.iframeWrap {
    width: 440px;
    height: 248px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.iframe {
    pointer-events: none;
    transform-origin: 0 0;
}