@value gray300 from "../../styles/colors.module.css";

.thumb {
  border-radius: 3px;
  width: 6px;
  height: 6px;
  background-color: gray300;
}

::-webkit-scrollbar {
  max-width: 8px;
  max-height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  padding: 8px 4px;
  background-color: transparent;
  margin-right: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: gray300;
}
