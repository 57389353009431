@value white300 from "../../styles/colors.module.css";

.root {
  padding: 0 16px 12px 16px;
  user-select: none;
}

.topbar {
  height: 56px;
  line-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.title {
  flex-grow: 1;
  user-select: none;
  color: white300;
}

.actionMenu {
  margin-left: 8px;
}
