.root {
  display: flex;
  flex-direction: column;
}

.searchBox {
  flex-shrink: 0;
  z-index: 9999;
  margin-bottom: 8px;
}

.map {
  flex-grow: 1;
  position: relative;
  cursor: default;
}

.map * {
  cursor: default;
}

.location {
  composes: location from "../index.module.css";
}
