@value black300, gray300 from "../../../../../styles/colors.module.css";

.root {
  margin-left: 48px;
}

.root, .expansionRow {
  padding-right: 70px;
}

.serviceRegionContainer {
  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: 6px;
}

.credentialsContainer {
  display: flex;
  justify-content: space-between;
}

.oneCredentialContainer {
  width: 196px;
}

.controls {
  background-color: black300;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 2px;
}

.expansionContent {
  padding: 0 16px 16px 16px;
}

.expansionLabel {
  font-weight: 400 !important;
  color: gray300 !important;
  display: flex;
  flex-direction: column;
}

.status {

}
