@value black100, black200, black200, blackPure, white300, gray300 from "../../../styles/colors.module.css";

.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
}

.nothing,
.test-pattern,
.custom-source,
.black {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothing {
  background-color: black200;
}

.test-pattern {
  background-color: black200;
  background-image: linear-gradient(rgba(38, 41, 44, 0.851), rgba(38, 41, 44, 0.85)), url(./images/bars-tone.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  color: gray300;
}

.custom-source {
  background-color: white300;
  color: black100;
}

.black {
  background-color: black;
}
