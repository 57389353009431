@value black100, black200, black500 from "../../../styles/colors.module.css";

.root {
  border-bottom: 1px solid black200;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 2px;
  height: 18px;
  grid-column: 1/-1;
}

.bright {
  border-color: black100;
}

.icon {
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 4px;
  margin-left: -4px;
}

.label {
  color: black500;
  font-size: 12px;
  flex-grow: 1;
}
