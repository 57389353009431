@value gray300, black100, black200, white300 from "../../../../styles/colors.module.css";

.tabContainer {
}

.qualityDetails {
  font-size: 10px;
}

.titleSubtitle {
  display: inline-block;
}

.expansionTitleMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  line-height: 10px;
}

.expansionTitleLabel {
  margin-right: 4px;
  font-size: 10px;
}

.expansionTitleSub {
  padding-left: 24px;
}

.value {
  color: gray300;
  line-height: 24px;
  height: 18px;
  margin: auto 0;
}

.typeIcon {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: white300;
}

.addBtnHolder {
  position: absolute;
  width: 536px;
  display: flex;
  background-color: black200;
  z-index: 10000;
  top: 74px;
  padding: 16px 48px 16px 48px;
}

.pushTargetsLimitWarning {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 12px;
}

.message {
  margin-bottom: 8px;
}

.pushTargetsList {
  padding-top: 48px;
}

.pushTarget {
  scroll-margin-top: 48px;
}

.qualityError {
  bottom: calc(100% + 4px);
}
